/* Responsive video embed */
.youtube-block {
    .embed-youtube {
        position: relative;
        width: 100%;
        overflow: hidden;
        -webkit-box-shadow: 0 0 18px -5px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0 0 18px -5px rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 18px -5px rgba(0, 0, 0, 0.5);

        &:hover .youtube-video-overlay .play .icon {
            opacity: 1;
            transition: all .25s;
            transform: scale(1.3);
        }
        &:hover .youtube-video-overlay .darken {
            transition: all .25s;
            background: rgba(0, 0, 0, .15);
        }
        .youtube-video-overlay {
            width: 100%;
            height: 100%;
            overflow: hidden;

            &:hover {
                cursor: pointer;
            }
            .darken {
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, .35);
                position: absolute;
                z-index: 20;
                transition: all .25s;
            }
            .play {
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                z-index: 30;

                .icon {
                    font-size: 72px;
                    color: $white;
                    opacity: .8;
                    transition: all .25s;
                }
            }
            .thumbnail {
                width: 100%;
                padding: 0 15px;
                position: absolute;
                left: 0;
                z-index: 10;
            }
        }
        .info-bar {
            position: absolute;
            bottom: 0;
            width: 100%;
            background: $white;
            z-index: 30;

            p {
                margin: 0;

                @media (max-width: 575px) {
                    font-size: 16px;
                }
            }
        }
        .youtube {
            position: relative;
            padding-bottom: 56.25%;
            height: 0 !important;
            overflow: hidden;
            max-width: 100%;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            object {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            embed {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    .bottom-bar {
        position: relative;
        background: $gray-200;
        display: block;

        .share-bar {
            display: inline-block;

            .text {
                color: $gray-700 !important;
                padding: 6px 0;
            }
            .icons {
                margin-left: 5px;

                .share-icon {
                    display: inline-block;
                    width: 38px;
                    height: 38px;
                    padding: 8px;
                    margin-left: 10px;
                    background: $sl-green-dark;
                    border-radius: 50%;

                    .icon {
                        font-size: 20px;
                        color: $white;
                    }
                    &:hover {
                        background: $sl-green-dark;
                    }
                }
                @media (max-width: 575px) {
                    float: none !important;
                    margin-left: 0;
                }
            }
        }
    }
}

.content-grey {
    .youtube-block, .bottom-bar {
        background: $white !important;
    }
    &.video-showcase {
        .youtube-block {
            background: $gray-200 !important;
        }
    }
}

.youtube-block.not-full-width-video {
    .bottom-bar {
        .share-bar {
            .icons {
                @media (max-width: 1199px) {
                    float: none !important;
                    margin-left: 0;
                }
            }
        }
    }
}

.not-full-width-video {
    width: 70% !important;
    margin: 0 auto;

    @media (max-width: 991px) {
        width: 100% !important;
    }
}

@media (max-width: 767px) {
    .youtube-block {
        .embed-youtube {
            &.full-width-section {
                width: 100% !important;
            }
        }
    }
}

@media (max-width: 480px) {
    .youtube-block {
        .embed-youtube {
            .youtube-video-overlay {
                .play {
                    .icon {
                        font-size: 52px;
                    }
                }
            }
        }
        .bottom-bar {
            .share-bar {
                .text {
                    font-size: 16px;
                }
            }
        }
    }
}
