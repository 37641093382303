/* Builder Blocks - Three Row */

/* Three Text Box */
.three-text-box {
    padding-bottom: 35px !important;

    .content {
        border-right: 1px solid $white;
        &:last-child {
            border-right: none !important;
        }
        p {
            margin-bottom: 0;
        }
    }
    .stat {
        font-size: 44px;
        font-weight: 700;
    }
}
.three-text-box.brand-1-bg {
    .content {
        border-right: 1px solid $white;

        .stat {
            color: $gray-700;
        }
    }
    h2 {
        color: $gray-700;
    }
}
.three-text-box.brand-2-bg {
    .content {
        border-right: 1px solid $white;

        .stat {
            color: $sl-green;
        }
    }
    h2 {
        color: $sl-green;
    }
}
.three-text-box.grey-bg {
    .content {
        border-right: 1px solid $gray-400;

        .stat {
            color: $sl-green;
        }
    }
    h4 {
        color: $gray-700 !important;
    }
}
.three-text-box.white-bg {
    .content {
        border-right: 1px solid $gray-400;

        .stat {
            color: $gray-700;
        }
    }
    h4 {
        color: $gray-700 !important;
    }
}

@media (max-width: 767px) {
    .three-text-box {
        .content {
            border-right: 0 !important;
            border-bottom: 1px solid $white;
            padding-top: 15px;

            p {
                margin-bottom: 1rem;
            }

            &:first-child {
                padding-top: 0 !important;
            }
            &:last-child {
                border-bottom: 0 !important;

                p {
                    margin-bottom: 0;
                }
            }
        }
    }
    .three-text-box.grey-bg, .three-text-box.white-bg {
        .content {
            border-bottom: 1px solid $gray-400;
            padding-top: 15px;
        }
    }
}

@media (max-width: 575px) {
    .three-text-box {
        border: 0 !important;
    }
}

/* Three Image Box */
.three-image-box {
    h3 {
        color: $gray-700 !important;
    }
    .carousel-inner {
        width: 80%;
    }
    .usp {
        width: 130px;
        height: auto;
    }
    .owl-theme {
        .owl-stage {
            margin: 0 auto;
        }
        .owl-nav {
            height: 90px;
        }
    }
}
