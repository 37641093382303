/* Builder Blocks - Quick Survey Block */
.quick-survey-block {
    [data-mode="quicksurvey"] {
        background-color: $white;
        border: 1px solid $primary;
        border-top: 6px solid $primary !important;

        h3 {
            font-size: 2rem;
        }
        .call-back-form {
            .field {
                display: block !important;
            }
            .form-control {
                background: $gray-200;
                border: 1px solid $white;
            }
            sup i {
                color: $sl-red-light;
                font-weight: 700;
                font-size: 8px;
            }
            span.currency {
                position: absolute;
                display: block;
                margin: 7px 0 0 10px;
            }
            input[type='number'] {
                padding-left: 24px;
            }
        }
        .fas {
            color: $sl-green;
        }
        a {
            color: $sl-green;
            text-decoration: underline;

            &:hover {
                color: $sl-green-dark;
                text-decoration: underline;
            }
        }
    }
    @media (min-width: 992px) {
        [data-mode="quicksurvey"] {
            .call-back-form {
                .submit button {
                    width: calc(50% - 10px) !important;
                }
            }
        }
    }
    @media (max-width: 480px) {
        [data-mode="quicksurvey"] {
            h3 {
                font-size: 1.5rem;
            }
        }
    }
}
