/* Builder Blocks - Mini Quote */
.mini-quote {
    padding-bottom: 35px !important;
    overflow: hidden;

    blockquote {
        margin-bottom: 0;

        p {
            color: $white;
        }
    }
    .bottom {
        margin-top: -15px;

        .profile-pic {
            float: left;
            margin-right: 20px;
            width: 75px;
            height: 75px;
            border-radius: 50%;
            border: 2px solid white;
        }
    }
}
