/* FAQs */
.faq-posts {
    .accordion {
        .card {
            border: none;
            border-radius: 0;
            border-bottom: 1px solid $gray-200 !important;
            padding: 12px 0;

            &:last-child {
                padding-bottom: 0;
            }

            .card-header {
                padding: 0;
                background-color: $white;
                border-bottom: none;

                .btn {
                    font-size: 18px;
                    color: $sl-green-dark;
                    padding: .375rem 1rem .375rem 1.7rem;
                    text-transform: none !important;

                    &:hover {
                        color: $gray-700;
                        text-decoration: none !important;
                    }
                    &:focus {
                        color: $gray-700;
                        text-decoration: none !important;
                        box-shadow: none !important;
                    }
                    &:before, &:after {
                        position: absolute;
                        left: 0;
                        top: 33px;
                        transform: translateY(-50%);
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                    }
                    &:before {
                        content: "\f068";
                        color: var(--fa-primary-color, inherit);
                        opacity: 1;
                        opacity: var(--fa-primary-opacity, 0.4);
                    }
                    &:after {
                        content: "";
                    }
                    &.collapsed:before {
                        content: "\f068";
                        color: var(--fa-primary-color, inherit);
                        opacity: 1;
                        opacity: var(--fa-primary-opacity, 0.4);
                    }
                    &.collapsed:after {
                        content: "\f067";
                        color: var(--fa-secondary-color, inherit);
                        opacity: var(--fa-secondary-opacity, 1.0);
                    }
                }
            }
            &:first-child {
                .card-header {

                    .btn {
                        &:before, &:after {
                            top: 33px;
                        }
                    }
                }
            }
            &:last-child {
                border-bottom: none !important;
            }
            .card-body {
                padding: 0.3rem 1.7rem 0.3rem 1.8rem;
            }
        }
    }
}
