/* Builder Blocks - Case Study List */
.case-study-list {
    &.brand-1-bg {
        border: 2px solid $sl-green;
    }
    &.brand-2-bg {
        border: 2px solid $sl-blue;
    }
    &.grey-bg {
        border: 2px solid $gray-200;
    }
    &.white-bg {
        border: 2px solid $white;
    }
    .content {
        h6 {
            @media (max-width: 1199px) {
                font-size: 1rem;
                line-height: 1.6;
            }
        }
    }
}

@media (max-width: 767px) {
    .case-study-list {
        padding-bottom: 3px !important;
    }
}
