/* Builder Blocks - Conveyancing Explained */
.conveyancing-explained {
    padding-bottom: 55px;

    @media (min-width: 1400px) {
        background-size: 1500px;
        padding-bottom: 80px;
    }
    @media (max-width: 1024px) {
        background-size: 1700px;
        padding-bottom: 80px;
    }
    @media (max-width: 767px) {
        background-size: 1300px;
    }

    .btn {
        position: relative;
        top: 50%;
        left: 50%;
        width: 100%;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
    }
}
.main-content-area {
    .conveyancing-explained {
        border-radius: 6px;
        margin: 0 15px 40px;
        padding-top: 0 !important;
        -webkit-box-shadow: 0 1px 10px -6px rgba(0,0,0,.42), 0 1px 10px 0 rgba(0,0,0,.12), 0 4px 5px -2px rgba(0,0,0,.04);
        box-shadow: 0 1px 10px -6px rgba(0,0,0,.42), 0 1px 10px 0 rgba(0,0,0,.12), 0 4px 5px -2px rgba(0,0,0,.04);

        .btn {
            padding: 4px 6px;
            font-size: 14px;
        }
    }
}
