/* Builder Blocks - Case study */
.case-study {
    .owl-theme {
        .owl-dots {
            position: absolute;
            right: 0;
            bottom: -46px;
        }
        .owl-nav {
            font-size: 24px;
            height: 34px;

            .owl-prev {
                left: 0;
                margin: 0;
            }
            .owl-next {
                right: 0;
                margin: 0;
            }
        }
    }
    p, blockquote {
        margin-bottom: 0 !important;
    }
    .person {
        font-size: 20px;
    }
    .btn {
        position: relative;
        left: 15px;
    }
    &.grey-bg, &.white-bg {
        .owl-theme {
            .owl-nav {
                .fa {
                    color: $sl-green;
                }
            }
        }
    }
    @media (max-width: 420px) {
        .owl-theme {
            .owl-dots {
                position: relative;
                bottom: 0;
            }
        }
        blockquote {
            padding: 1.5rem 2.2rem 1.5rem 2.6rem;

            p {
                font-size: 16px;
            }
            &:before, &:after {
                font-size: 2em;
            }
        }
        .btn {
            width: 100%;
            left: 0;
        }
    }
}
