/* Surveyor section */
.surveyor-section {
    position: relative;
    padding-bottom: 0;
    margin-bottom: -80px;
    background: $gray-200;
    background: linear-gradient(180deg,#e9ecef,#e9ecef 5%,rgba(233,236,239,0));
    overflow: hidden;
    z-index: 10;

    hr.divider {
        position: absolute;
        width: 150px;
        height: 6px;
        margin-top: 10px;
        background: $sl-green;
        border-radius: 3px;
    }
    .surveyors {
        margin-top: 60px;

        .divider {
            margin-right: 12px;
        }
        .a-z,
        .ldn {
            a {
                text-decoration: none !important;
            }
        }
        .a-z {
            overflow-wrap: break-word;

            a {
                font-weight: 700;
                margin-right: 12px;
            }
        }
    }
    .map {
        img {
            position: relative;
            top: -30px;
            width: 100%;
            max-height: 280px;

            @media (max-width: 1199px) {
                top: -10px;
            }
        }
    }
}
