/* Builder Blocks - Image Gallery */
.image-gallery-block {
    padding-top: 25px;
    margin-bottom: -40px;

    .owl-carousel-cell-img.owl-theme {
        .owl-stage-outer {
            .owl-item {
                a img {
                    transition: .3s all;

                    &:hover {
                        opacity: .75;
                        transition: .3s all;
                    }
                }
                &.active {
                    height: auto;
                }
            }
        }
        .owl-nav {
            position: relative;
            top: 20px;
            height: 42px;

            .owl-prev, .owl-next {
                position: absolute;
                opacity: 1;
                transition: .3s all;

                .fa {
                    color: $sl-green;
                }
                &:hover {
                    opacity: .6;
                    transition: .3s all;
                }
            }
            .owl-prev {
                left: 0;
            }
            .owl-next {
                right: 0;
            }
        }
        .owl-dots {
            position: relative;
            top: -32px;
            margin: 0 20px;
        }
    }
}
