/* Builder Blocks - Trust logos */
.trust-logos {
    .logos {
        padding-left: 1.2rem !important;

        img {
            width: 100%;
        }
    }
}
@media (max-width: 767px) {
    .trust-logos {
        .logos {
            text-align: center !important;
            padding-left: 0 !important;

            img {
                margin-top: 20px;
                float: none !important;
            }
        }
    }
}
