/* Search results */
form#searchform {
    input {
        border: 1px solid #f1f1f1;

        &::placeholder {
            color: #8c8c8c !important;
        }
    }
}
.search-wrap {
    position: relative;
    top: -30px;
}
#search-results {
    .ss-result__aside {
        display: none;
    }
    h4.ss-result__title {
        color: $sl-green-dark;
    }
    hr {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    ul.pagination {
        position: relative;
        top: -25px;
        font-size: 14px;
        font-weight: 700;
        justify-content: center !important;
        margin-left: 0 !important;
        margin-bottom: -85px !important;
        padding-top: 20px;
        padding-left: 15px !important;
        background: white;

        li {
            left: 0;
            text-indent: 0 !important;
            text-align: center;
            width: auto;
            max-height: 31px;

            span {
                height: 31px;
                display: block;
                padding: 4px 10px;
                color: #6C757D;
                border-top: 1px solid #DEE2E6;
                border-bottom: 1px solid #DEE2E6;
            }
            &:nth-child(2) {
                border-top-left-radius: .25rem;
                border-bottom-left-radius: .25rem;
            }
            &:first-child {
                a {
                    border-radius: .25rem 0 0 .25rem;
                    font-size: 0;

                    &:before {
                        content: "\f053";
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        font-size: 14px;
                        color: $gray-700;
                    }
                }
                &.active {
                    border-radius: .25rem 0 0 .25rem;
                }
            }
            &:last-child {
                a {
                    border-radius: 0 .25rem .25rem 0;
                    font-size: 0;

                    &:after {
                        content: "\f054";
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        font-size: 14px;
                        color: $gray-700;
                    }
                }
                &.active {
                    border-radius: 0 .25rem .25rem 0;
                }
            }
            a {
                position: relative;
                display: block;
                margin-left: -1px;
                background-color: $white;
                padding: 6px 10px;
                border: 1px solid #DEE2E6;
                line-height: 1.25;
                text-decoration: none;

                &:hover {
                    background-color: #E9ECEF;
                    border-color: #DEE2E6;
                }
            }
            &.disabled {
                cursor: default;
            }
            &.active {
                cursor: default;
                color: $white;
                background-color: $sl-green-dark;
                border-color: $sl-green-dark;
                padding: 5px 10px;

                span {
                    display: block;
                    border: none !important;
                    color: $white !important;
                    padding: 0 !important;
                }
            }
            &:before {
                content: none;
            }
        }
    }
}
