/* Main header form */
section.call-back-form-header {
    @media (min-width: 1921px) {
        .row {
            margin-right: -15px !important;
            margin-left: -15px !important;
        }
    }

    @media (min-width: 992px) {
        .container {
            top: 34px;
            position: relative;
            z-index: 99;
        }
    }

    @media (max-width: 991px) {
        top: 40px;
        margin-top: -60px;

        .container {
            h5 {
                text-align: left !important;
            }
        }
    }

    @media (max-width: 767px) {
        text-align: left;
    }

    .call-back-form {
        .form-control {
            background: $gray-200;
            border: 1px solid $white;
        }
        sup i {
            color: $sl-red-light;
            font-weight: 700;
            font-size: 8px;
        }
        span.currency {
            position: absolute;
            display: block;
            margin: 8px 0 0 10px;
        }
        input[type='number'] {
            padding-left: 24px;
        }
    }

    .container {
        color: $gray-700;
        background-color: $white;
        border-left: 6px solid $primary;

        .fas {
            color: $sl-green;
        }
        a {
            color: $sl-green;
            text-decoration: underline;

            &:hover {
                color: $sl-green-dark;
                text-decoration: underline;
            }
        }
        h5 {
            position: relative;
            top: -16px;
            color: $white;
            text-align: center;
            background: $gray-700;
            padding: 15px;
            margin-bottom: -1px;
            margin-left: -24px;
            margin-right: -24px;
            border-top-left-radius: .25rem;
            border-top-right-radius: .25rem;
        }
    }
}

/* Sticky drop-down form */
section#call-back-form-sticky {
    position: fixed;
    top: -465px;
    width: 100%;
    z-index: 110100;
    background: $sl-blue;
    color: $white;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;

    p {
        font-size: 16px;
    }
    .form-control {
        background: $white;
        border: 1px solid $gray-200;

        &:focus {
            box-shadow: 0 0 0 0.2rem rgba(255,255,255,.4);
        }
    }
    .fas {
        color: $white;
    }
    a {
        text-decoration: underline;
    }
    .submit {
        padding: .8rem 5rem;

        @media (max-width: 767px) {
            padding: .8rem 4px;
            width: 100%;
        }
    }
    .call-back-form-sticky-close {
        color: $white;
        background: darken( $sl-blue, 10% );
        font-weight: 400;
        -webkit-transition: all .3s ease-in-out;
        -moz-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;

        .fas {
            position: relative;
            top: 2px;
            color: $white;
            font-size: 21px;
            margin-left: 10px;
        }
        &:hover {
            cursor: pointer;
            background: darken( $sl-blue, 20% );
            -webkit-transition: all .4s ease-in-out;
            -moz-transition: all .4s ease-in-out;
            -o-transition: all .4s ease-in-out;
            transition: all .4s ease-in-out;
        }
    }
    &.call-back-sticky-anim {
        -webkit-transition: all .4s ease-in-out;
        -moz-transition: all .4s ease-in-out;
        -o-transition: all .4s ease-in-out;
        transition: all .4s ease-in-out;
    }
}

/* Footer form */
section.call-back-form-footer {
    position: relative;
    top: 210px;
    text-align: center;
    margin-top: -120px;

    @media (max-width: 1199px) {
        top: 190px;
    }
    @media (max-width: 991px) {
        margin-bottom: 20px;
    }
    @media (max-width: 767px) {
        text-align: left;
    }
    @media (max-width: 575px) {
        top: 130px;
        width: 100% !important;
        margin-top: -70px;
        margin-bottom: -30px;
    }
    .container {
        position: relative;
        top: -40px;
        background-color: $white;
        padding: 30px 160px;
        border-top: 6px solid $primary;
        z-index: 20;

        @media (max-width: 991px) {
            padding: 50px 60px;
        }
        @media (max-width: 767px) {
            padding: 1.5rem;
        }
        @media (max-width: 575px) {
            width: calc(100% - 30px);
        }

        h4 {
            font-size: 2rem;
        }
        h4, .lead {
            @media (max-width: 480px) {
                font-size: 1.3rem !important;
            }
            @media (max-width: 375px) {
                font-size: 1.2rem !important;
            }
        }
        form {
            padding: 0 50px !important;

            .field {
                text-align: left;

                .form-control {
                    background: $gray-200;
                    border: 1px solid $gray-200;
                }
            }
            .submit {
                width: auto !important;

                button {
                    width: auto !important;
                    min-width: 240px;
                    padding: .8rem 1.5rem;
                }
            }
            sup i {
                color: $sl-red-light;
                font-weight: 700;
                font-size: 8px;
            }
            span.currency {
                position: absolute;
                display: block;
                margin: 8px 0 0 10px;
            }
            input[type='number'] {
                padding-left: 24px;
            }
            @media (max-width: 767px) {
                padding: 0 !important;

                .field {
                    padding: 0 15px !important;
                }
                .submit {
                    width: 100% !important;

                    button {
                        width: 100% !important;
                        padding: 6px 12px;
                    }
                }
            }
        }
        .call-back-privacy-link {
            .fas {
                color: $primary;
            }
        }
        a {
            text-decoration: underline;
        }
        .hr-divider {
            position: absolute;
            left: -145px;
            width: calc(100% + 290px);
            border-top: 1px solid $gray-500;
            margin-top: 35px;

            @media (max-width: 991px) {
                left: -45px;
                width: calc(100% + 90px);
            }
            @media (max-width: 767px) {
                left: -10px;
                width: calc(100% + 20px);
            }
        }
        .more-info {
            padding-top: 65px;

            span.lead {
                font-size: 24px;
                line-height: 1.3;
            }
        }
    }
}

/* Sidebar form */
div.callback-form-sidebar {
    color: $white;
    background: $sl-blue;

    .call-back-form {
        .form-control {
            background: $white;
            border: 1px solid $white;
        }
    }
    h5 {
        font-size: 1.15rem;
    }
    .fas {
        color: $white;
    }
    a {
        color: $white;
        text-decoration: underline;

        &:hover {
            color: $white;
            text-decoration: none;
        }
    }
    .submit {
        padding: 6px 0;
        width: calc(100% - 30px);
    }
    form {
        .row {
            div {
                width: 100% !important;
                max-width: 100% !important;
                flex: 0 0 100% !important;
            }
            .call-back-form-notice {
                -ms-flex-order: 5;
                order: 5;
            }
            .submit {
                -ms-flex-order: 6;
                order: 6;
            }
        }
    }
}

/* PPC form */
section.call-back-form-ppc {
    color: $white;
    background-color: $sl-blue;

    .form-control {
        background: $white;
        border: 1px solid $white;
    }
    .fas {
        color: $white;
    }
    a {
        color: $white;
        text-decoration: underline;

        &:hover {
            color: $white;
            text-decoration: none;
        }
    }
    form {
        .row {
            .call-back-form-notice {
                -ms-flex-order: 5;
                order: 5;
            }
            div {
                width: 100% !important;
                max-width: 100% !important;
                flex: 0 0 100% !important;
            }
            .call-back-form-notice {
                -ms-flex-order: 5;
                order: 5;
            }
            .submit {
                padding: 6px 15px;
                -ms-flex-order: 6;
                order: 6;
            }
        }
    }
    .ppc-call-back-form-boxed {
        background: $sl-blue;

        a {
            color: $white;
            text-decoration: underline;

            &:hover {
                color: $white;
                text-decoration: none;
            }
        }
    }
    .call-back-form {
        .row {
            .call-back-form-notice {
                width: 100%;
                max-width: 100%;
                flex: 0 0 100%;
                text-align: left !important;
            }
            div {
                width: 50%;
                max-width: 50%;
                flex: 0 0 50%;

                @media (max-width: 991px) {
                    width: 100%;
                    max-width: 100%;
                    flex: 0 0 100%;
                }
            }
            .submit {
                width: 100%;
                max-width: 100%;
                flex: 0 0 100%;
            }
        }
    }
}

/* Global styles field positioning */
section.call-back-form-header,
section.page-section {
    .call-back-form {
        .field {
            padding: 0 10px;
        }
        .submit {
            padding: 0 10px;
        }
    }
}
section#call-back-form-sticky {
    .call-back-form {
        .field {
            padding: 0 4px;

            sup i {
                color: $sl-green;
                font-weight: 700;
                font-size: 8px;
            }
        }
    }
}

/* Privacy link and icon styles */
.call-back-privacy-link {
    font-size: 14px;
    line-height: 20px;

    .fas {
        position: relative;
        bottom: 1px;
        font-size: 12px;
        margin-right: 6px;
    }
}

/* Call back form notice visibility applied to all form areas */
section.call-back-form-header .call-back-form-notice,
section.call-back-form-ppc .call-back-form-notice,
section#call-back-form-sticky .call-back-form-notice,
section.call-back-form-footer .call-back-form-notice,
section.page-section .call-back-form .call-back-form-notice,
aside#sidebar .call-back-form .call-back-form-notice {
    visibility: hidden;
    opacity: 0;
    margin: -10px 0 0 0;

    p {
        font-size: 14px !important;
    }
    &.on {
        visibility: visible;
        opacity: 1;
        -webkit-transition: opacity 0.3s;
        transition: opacity 0.3s;
        @media (max-width: 767px) {
            display: block;
        }
    }
}

/* Form button spinner */
.call-back-form button[type="submit"].oc-loading {
    font-size: 0;
    &:after {
        content: " ";
        font-size: initial;
        width: 25px;
        height: 25px;
        border-top-color: $white;
        border-left-color: $white;
        animation: spinner 400ms linear infinite;
        border-bottom-color: transparent;
        border-right-color: transparent;
        border-style: solid;
        border-width: 2px;
        border-radius: 50%;
        box-sizing: border-box;
        display: inline-block;
        vertical-align: middle;
    }
}

@keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Fixed max width on bigger screens */
@media (min-width: 1600px) {
    section.call-back-form-header,
    section.call-back-form-ppc,
    section#call-back-form-sticky,
    section.call-back-form-footer,
    section.page-section,
    aside#sidebar {
        .call-back-form {
            max-width: 960px !important;
            margin: 0 auto;
        }
    }
}
