/* Survey Quote */
.survey-quote {
    .loading {
        animation: blinker .75s linear infinite;
    }
    @keyframes blinker {
        50% {
            opacity: 0;
        }
    }
    .result {
        background: $gray-200;
        overflow: hidden;

        .top {
            margin: -24px -24px 24px -24px;
            background: $gray-400;

            @media (max-width: 380px) {
                font-size: 80%;
            }
        }
        .card-group {
            .card {
                border: 1px solid;
                transition: all .25s linear;

                .card-header {
                    border-bottom: none;

                    .icon {
                        transition: all .3s;
                    }
                    &.title {
                        border-bottom: 1px solid rgba(255,255,255,.3);
                        background-color: rgba(33,33,33,.15);
                    }
                    .price {
                        font-size: 28px;
                    }
                    @media (max-width: 991px) {
                        img {
                            max-width: 146px;
                        }
                    }
                    @media (max-width: 320px) {
                        img {
                            display: none;
                        }
                    }
                }
                .card-body {
                    border-bottom: 1px solid rgba(255,255,255,.3);
                    font-size: 16px;

                    .lead {
                        border-bottom: 2px dotted $sl-blue-dark;
                    }
                    .btn {
                        border: 1px solid $white;
                        margin-bottom: 15px;
                        box-shadow: none;

                        &:after {
                            content: "\f077";
                            font-family: "Font Awesome 5 Free";
                            font-weight: 900;
                            float: right;
                            transition: transform .25s ease-in-out;
                        }
                        &.collapsed {
                            &:after {
                                transform: rotate(-180deg);
                                transition: transform .25s ease-in-out;
                            }
                        }
                    }
                    ul {
                        margin-left: 5px;

                        li {
                            text-indent: -1em;
                            margin-bottom: 0;

                            &:before {
                                content: "\f00c";
                                left: -10px;
                                margin-right: 0;
                                color: $white;
                            }
                        }
                    }
                }
                .card-footer {
                    background-color: rgba(33,33,33,.15);
                    border-top: none;

                    .price {
                        font-size: 28px;
                    }
                    @media (max-width: 320px) {
                        .price {
                            font-size: 22px;
                        }
                    }
                }
                &:hover {
                    transform: scale(1.03);
                    transition: all .25s ease-in-out;
                    z-index: 10;
                    @extend .rounded;
                    @extend .shadow;

                    .card-header {
                        .icon {
                            transform: rotateY(180deg);
                            transition: transform .75s;
                        }
                        transition: all .75s linear;
                        @extend .rounded-top;
                    }
                }
                @media (max-width: 991px) {
                    margin-bottom: 20px;
                    box-shadow: 0 .5rem 1rem rgba(33,33,33,.15);
                    transform: scale(1) !important;

                    .card-header {
                        .icon {
                            transform: rotateY(0) !important;
                        }
                    }
                }
            }
            @media (min-width: 992px) {
                box-shadow: 0 .5rem 1rem rgba(33,33,33,.15);
            }
            @media (max-width: 991px) {
                display: block;

                .card {
                    border: 2px solid;
                    border-radius: 4px !important;

                    .card-header {
                        border-top-left-radius: 4px !important;
                        border-top-right-radius: 4px !important;
                    }
                }
            }
            @media (max-width: 320px) {
                .card {
                    border: 1px solid;
                }
            }
        }
        @media (min-width: 768px) {
            .collapse {
                display: block;
                height: auto !important;
                visibility: visible;
            }
        }
        .bottom {
            margin: 0 -24px -24px -24px;
            color: $white;
            background: $gray-700;

            .opening-times {
                span.time {
                    &:last-child {
                        span.divider {
                            display: none !important;
                        }
                    }
                }
            }
        }
        .curl {
            position: absolute;
            bottom: 24px;
            right: 14px;
            width: 80px;
            height: 80px;
            border-radius: 6px 0 0 0;
            background: linear-gradient(135deg,#fff,#f3f3f3 45%,#ddd 50%,#cecece 0,#fff 54%,#fff 75%,#fff);
            -webkit-box-shadow: -4px -4px 5px 0px rgba(0,0,0,.2);
            -moz-box-shadow: -4px -4px 5px 0px rgba(0,0,0,.2);
            box-shadow: -4px -4px 5px 0px rgba(0,0,0,.2);

            &:before,
            &:after {
                content: "";
                position: absolute;
                z-index: -1;
                left: 12.5%;
                bottom: 5.8%;
                width: 70%;
                max-width: 300px;
                max-height: 100px;
                height: 55%;
                transform: skew(-10deg) rotate(-6deg);
            }
        }
        &.extras {
            .icon {
                max-width: 100px;
            }
        }
    }
}
