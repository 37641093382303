/* Footer */
footer {
    position: relative;

    .footerart-1,
    .footerart-2 {
        position: absolute;
        top: 60px;
        width: 140px;
        height: auto;
        z-index: 10;
    }
    @media (min-width: 1921px) {
        .footerart-1 {
            left: 120px;
        }
        .footerart-2 {
            right: 120px;
        }
    }
    @media (max-width: 1920px) {
        .footerart-1 {
            left: 65px;
        }
        .footerart-2 {
            right: 65px;
        }
    }
    @media (max-width: 1680px) {
        .footerart-1,
        .footerart-2 {
            width: 100px;
            top: 80px;
        }
        .footerart-1 {
            left: 40px;
        }
        .footerart-2 {
            right: 40px;
        }
    }
    .footer-waves-blend {
        position: relative;
        top: 4px;
        width: 100%;
        height: 100px;
        background: $gray-200;
        background: linear-gradient(0deg, rgba(233,236,239,1) 0%, rgba(233,236,239,1) 5%, rgba(233,236,239,0) 100%);
    }
    .footer-waves {
        position: relative;
        bottom: -2px;
        width: 100%;
        height: auto;
        background: $gray-200;
        overflow: hidden;

        img {
            position: relative;
            width: 100%;

            @media (max-width: 767px) {
                width: 120%;
                left: -20%;
            }
            @media (max-width: 575px) {
                width: 140%;
                left: -40%;
            }
            @media (max-width: 375px) {
                width: 180%;
                left: -80%;
            }
        }
    }
    #footer-links {
        position: relative;
        background: $sl-blue-dark;
        z-index: 10;

        h5 {
            color: $sl-green;
            font-weight: 400;
        }
        ul {
            li {
                margin: 12px 0;

                a {
                    color: $white !important;

                    &:before {
                        position: relative;
                        left: 0;
                        content: "\f054";
                        font-family: "Font Awesome 5 Free";
                        font-size: 15px;
                        font-weight: 900;
                        color: $sl-green;
                        margin-right: 8px;
                        transition: all .25s;
                    }
                    &:hover {
                        color: $sl-green !important;

                        &:before {
                            left: 5px;
                            transition: all .25s;
                        }
                    }
                }
            }
        }
        .rics {
            width: 150px;
            height: auto;
        }
        .divider-row {
            border-bottom: 1px solid $sl-green;
        }
        .footer-widget.find-surveyors-form {
            form {
                input {
                    border-color: $white;
                }
            }
        }
        @media (max-width: 991px) {
            -webkit-box-shadow: 0px -10px 0px 0px rgba(14,51,76,0.5);
            box-shadow: 0px -10px 0px 0px rgba(14,51,76,0.5);
        }
    }
    #footer-menu {
        background: $sl-blue-dark;

        a {
            color: $sl-green !important;
            text-decoration: underline;

            &:hover {
                color: $white !important;
            }
        }
        nav {
            ul {
                li {
                    display: inline !important;
                    padding: 0 12px;
                    border-left: 1px solid $white;

                    &:first-child {
                        border-left: none;
                        padding-left: 0;
                    }
                }
            }
        }

        p {
            margin: 0;
        }
        .social-links {
            display: inline;

            a {
                margin-left: 15px;
                padding: 10px 0;

                &:first-child {
                    margin-left: 0;
                }

                .icon {
                    color: $white;
                    padding: 12px;
                    border-radius: 50%;
                    border: 2px solid $sl-green;
                    width: 48px;
                    height: 48px;
                    text-align: center;
                    font-size: 24px;
                    line-height: 24px;

                    &:hover {
                        color: $sl-blue-dark;
                        background: $white;
                        border: 2px solid $white;
                    }
                }
            }
        }
    }
    span.completion-protection,
    span.fixed-fee {
        color: $link-color;
        transition: .25s all;

        &:hover {
            cursor: pointer;
            color: $white;
            transition: .25s all;
        }
    }
    .modal {
        z-index: 200000;

        .modal-dialog {
            .modal-content {
                .modal-header {
                    .modal-title {
                        font-weight: 700;
                    }
                    .close{
                        opacity: 1;
                        transition: .25s all;

                        &:hover {
                            opacity: .6;
                            transition: .25s all;
                        }
                    }
                }
                .modal-body {
                    font-size: 18px;

                    p {
                        margin-bottom: 1rem !important;
                    }
                }
            }
        }
    }

    #bottom-bar {
        img.nahl {
            float: right;
            width: 120px;
            transition: .3s all;

            &:hover {
                opacity: .6;
                transition: .3s all;
            }
        }
    }
}

/* Responsive */
@media (max-width: 991px) {
    footer {
        #bottom-bar {
            img.nahl {
                float: none;
                transition: .3s all;

                &:hover {
                    opacity: .6;
                    transition: .3s all;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    footer {
        #footer-menu {
            nav {
                ul {
                    li {
                        display: block !important;
                        padding: 0;
                        border-left: none;
                    }
                }
            }
        }
    }
}

/* Cookie policy notice */
.cookie-policy-notice {
    &.page {
        .cookie-policy-actions {
            .action {
                &.accept {
                    .btn {
                        max-width: 250px !important;
                    }
                }
            }
        }
    }
    .modal-dialog {
        max-width: 720px;

        .modal-content {
            .close {
                position: absolute;
                right: 24px;
                z-index: 111030;
                transition: .25s all;

                &:focus {
                    outline: none;
                }
                &:hover {
                    transition: .25s all;
                }
            }
            .modal-body {
                padding: 3rem !important;
            }
        }
    }
    .cookie-policy-actions {
        margin-bottom: 1.9rem;

        .action {
            width: 50%;

            &.accept {
                .btn {
                    color: $white;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
        &.manage-cookies {
            margin-bottom: 0;

            .action {
                width: 100%;

                &.accept {
                    .btn {
                        padding: .7rem 1rem;
                        font-size: 1.1rem;
                    }
                }
            }
        }
    }
    .cookie-policy-options {
        .option {
            position: relative;

            .heading {
                float: left;

                h4 {
                    font-size: 18px;
                    line-height: 2.5;
                    padding-left: 26px;

                    &[aria-expanded="true"] {
                        &:before {
                            transform: rotate(0);
                        }
                    }
                    &:before {
                        position: absolute;
                        top: 0;
                        left: 0;
                        content: "\f077";
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        font-size: 18px;
                        color: $primary;
                        transform: rotate(180deg);
                        transition: .2s all;
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            .custom-switch {
                float: right;
                top: -8px;
                right: 25px;

                &.on {
                    right: 0;

                    span {
                        position: relative;
                        top: 22px;
                        font-size: 80%;
                        float: right;
                    }
                }
                .custom-control-label {
                    &:before {
                        height: 2rem;
                        width: calc(3rem + .75rem);
                        border-radius: 4rem;
                    }
                    &:after {
                        width: calc(2rem - 4px);
                        height: calc(2rem - 4px);
                        border-radius: calc(3rem - (2rem / 2));
                    }
                }
                .custom-control-input:active ~ .custom-control-label::before {
                    background: $primary !important;
                }
                .custom-control-input ~ .custom-control-label::before {
                    border-color: $sl-green-light !important;
                    box-shadow: none !important;
                }
                .custom-control-input ~ .custom-control-label::after {
                    background-color: $sl-green-light;
                }
                .custom-control-input:checked ~ .custom-control-label {
                    &:before {
                        border-color: $primary !important;
                        background: $primary;
                    }
                }
                .custom-control-input:checked ~ .custom-control-label::after {
                    background-color: $white;
                    transform: translateX(calc(2rem - .25rem));
                }
            }
        }
        .submit {
            float: right;
        }
    }
}
@media (max-width: 991px) {
    .cookie-policy-notice {
        .modal-dialog {
            margin: 0 auto !important;
            width: calc(100% - 30px);

            .modal-content {
                .modal-body {
                    padding: 1.7rem !important;
                }
            }
        }
        .cookie-policy-actions {
            .action {
                width: 100%;

                &.manage {
                    order: 2;

                    .btn {
                        padding: .25rem .7rem;
                    }
                }
                &.accept {
                    order: 1;
                    margin-bottom: 20px;

                    .btn {
                        padding: .7rem 1rem;
                        font-size: 1.1rem;
                    }
                }
            }
            &.manage-cookies {
                .action {
                    &.accept {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
@media (max-width: 767px) {
    .cookie-policy-notice {
        .modal-dialog {
            margin: 90px auto !important;
        }
    }
}
@media (max-width: 575px) {
    .cookie-policy-notice {
        .cookie-policy-options {
            #cookie-options-button {
                width: 100%;
                float: none;
            }
            .option {
                .heading {
                    h4 {
                        font-size: 16px ;
                        padding-left: 20px;

                        &:before {
                            top: -2px;
                            font-size: 15px;
                        }
                    }
                }
                .custom-switch {
                    &.on {
                        padding-left: 0 !important;

                        span {
                            font-size: 70%;
                        }
                    }
                    .custom-control-label {
                        &:before {
                            top: .5rem;
                            left: -1.5rem;
                            height: 1.5rem;
                            width: calc(2.2rem + .75rem);
                        }
                        &:after {
                            top: calc(.25rem + 6px);
                            left: calc(-1.5rem + 2px);
                            width: calc(1.5rem - 4px);
                            height: calc(1.5rem - 4px);
                        }
                    }
                    .custom-control-input:checked ~ .custom-control-label::after {
                        transform: translateX(calc(1.7rem - .25rem));
                    }
                }
            }
        }
        &.page {
            .cookie-policy-actions {
                .action {
                    &.accept {
                        .btn {
                            max-width: 100% !important;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 350px) {
    .cookie-policy-options {
        .option {
            .heading {
                h4 {
                    font-size: 14px !important;

                    &:before {
                        font-size: 16px !important;
                    }
                }
            }
            .custom-switch {
                &.on {
                    top: -12px !important;

                    span {
                        font-size: 70% !important;
                    }
                }
            }
        }
        p {
            font-size: 14px;
        }
    }
}
@media (max-height: 700px) {
    .cookie-policy-notice {
        .modal-dialog {
            margin: 90px auto !important;
        }
    }
}

/* Page progress status circle */
.progress-wrap {
    position: fixed;
    left: 25px;
    bottom: 60px;
    height: 56px;
    width: 56px;
    cursor: pointer;
    display: block;
    border-radius: 50px;
    box-shadow: 0 0 13px -2px rgba(0,0,0,.5);
    z-index: 10000;
    opacity: 0;
    visibility: hidden;
    transform: translateY(15px);
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;

    &.active-progress {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
    &::after {
        position: absolute;
        content: " v ";
        font-family: "Font Awesome 5 Free";
        text-align: center;
        line-height: 50px;
        font-size: 24px;
        color: $sl-green-dark;
        left: 6px;
        top: 5px;
        height: 46px;
        width: 46px;
        cursor: pointer;
        display: block;
        z-index: 1;
        -webkit-transition: all 200ms linear;
        transition: all 200ms linear;
        transform: rotate(180deg);
    }
    &:hover::after {
        color: $gray-700;
    }
    svg.progress-circle path {
        fill: $white;
        stroke: $sl-green-dark;
        stroke-width: 5;
        box-sizing:border-box;
        -webkit-transition: all 200ms linear;
        transition: all 200ms linear;
    }
}
