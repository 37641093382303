/* Builder Blocks - Making a claim */
.making-a-claim-block {
    counter-reset: claim-number;
    margin-bottom: 10px !important;

    .link {
        a {
            display: block;
            padding: 8px 0 8px 50px;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 5px;
            }
        }
        &:before {
            counter-increment: claim-number;
            content: "" counter(claim-number) " ";
            position: relative;
            display: block;
            float: left;
            clear: both;
            background: $gray-200;
            color: $sl-green-dark !important;
            width: 40px;
            height: 40px;
            font-weight: 700 !important;
            line-height: 1.3em;
            text-align: center;
            padding: 7px;
            border: 2px solid $gray-700;
            border-radius: 50%;
        }
    }
}
