/* Builder Blocks - Team quote */
.team-quote {
    padding-bottom: 45px !important;
    img {
        width: 100%;
    }
    .content {
        text-align: left !important;
        .tagline {
            margin-left: 34px;
        }
    }
    blockquote {
        padding-bottom: 0 !important;
        p {
            color: $white;
        }
        &:after {
            bottom: -24px !important;
        }
    }
    .person {
        font-size: 20px;
        font-weight: 700;
    }
    .btn {
        margin-left: 34px;
    }
    .team-job-title {
        font-size: 16px;
        color: $white;
        opacity: 0.6;
    }
}
@media (max-width: 767px) {
    .team-quote {
        .content {
            clear: both;
            padding-left: 15px;
        }
        .image {
            float: none !important;
        }
    }
}
