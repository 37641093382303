/* Builder Blocks - Triple Blocks */
.triple-blocks {
    counter-reset: process;

    .item-block {
        .card {
            border: none;
            overflow: hidden;
            padding-bottom: 55px !important;
            min-height: 500px;
            height: 500px;
            width: 100%;

            @media (max-width: 991px) {
                height: auto !important;
            }

            .card-body {
                .item {
                    position: relative;
                    margin: 15px 0;

                    &.title {
                        padding: 0 1rem;
                    }

                    &.sub-title {
                        padding: 0 1rem;
                    }

                    &.content {
                        float: none !important;
                        font-size: 16px;
                        padding: 0 1rem;
                    }

                    &.image {
                        float: none !important;

                        img {
                            width: 100%;
                        }
                    }

                    &.video {
                        overflow: hidden;

                        .youtube-video-overlay {
                            overflow: hidden;

                            .thumbnail {
                                width: 100%;
                            }

                            .darken {
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                background: rgba(0, 0, 0, .35);
                                transition: all .25s;
                            }

                            .play {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                -webkit-transform: translate(-50%, -50%);
                                -ms-transform: translate(-50%, -50%);
                                transform: translate(-50%, -50%);
                                z-index: 30;

                                .icon {
                                    font-size: 54px;
                                    color: $white;
                                    opacity: .8;
                                    transition: all .25s;
                                }
                            }

                            &:hover .play .icon {
                                opacity: 1;
                                transition: all .25s;
                                transform: scale(1.2);
                            }

                            &:hover .darken {
                                transition: all .25s;
                                background: rgba(0, 0, 0, .15);
                            }
                        }
                    }

                    &.cta-btn {
                        position: absolute;
                        bottom: 5px;
                        width: 100%;
                        padding: 0 1rem;
                    }

                    &.cta-link {
                        position: absolute;
                        bottom: 5px;
                        width: 100%;
                        padding: 0 1rem;

                        a.text-link {
                            font-size: 16px;
                        }
                    }

                    &.image:first-child, &.video:first-child {
                        margin-top: 0;
                    }
                }
            }
        }

        @media (max-width: 575px) {
            margin-bottom: 1rem !important;
        }
    }

    &.counter {
        .item-block {
            .card {
                .card-body {
                    .item.image {
                        .number {
                            position: relative;
                            margin: 0 auto;
                            bottom: 30px;
                            width: 55px;
                            height: 55px;
                            background: $sl-green;
                            border-radius: 50%;
                            margin-bottom: -32px;

                            &:before {
                                counter-increment: process;
                                content: counter(process);
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                -webkit-transform: translate(-50%, -50%);
                                -ms-transform: translate(-50%, -50%);
                                transform: translate(-50%, -50%);
                                color: $white;
                                font-size: 24px;
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
        }
    }
}
