/* Navigation */
header {
    #navigation {
        /* Desktop */
        @media (min-width: 1025px) {
            font-size: 16px;
            height: 42px;
            background: $gray-200;
            visibility: visible;
            overflow: hidden;
            opacity: 1;
            transition: visibility 0s linear 0s, opacity 300ms;

            &.call-back-active {
                visibility: hidden;
                opacity: 0;
                transition: visibility 0s linear 300ms, opacity 300ms;
            }
            .mobile-only {
                display: none !important;
            }
            .nav-sub-1, .nav-sub-2 {
                display: none;
            }
            nav.slide-out-menu {
                .menu-panels {
                    .nav-sub-0 {
                        .parent {
                            .menu-link {
                                font-weight: 400;

                                &:after {
                                    display: inline-block;
                                    content: "\f078";
                                    font-family: "Font Awesome 5 Free";
                                    font-weight: 900;
                                    font-size: 12px;
                                    margin-left: 8px;
                                    color: $sl-green;
                                    -webkit-transition: -webkit-transform .8s ease-in-out;
                                    transition: transform .8s ease-in-out;
                                    transition: all .3s ease;
                                }
                                &:hover {
                                    cursor: pointer;
                                }
                            }
                            &.current {
                                .menu-link.current {
                                    color: $sl-blue-dark;
                                    background: lighten($sl-green-light, 20%);
                                }
                                &:hover {
                                    .menu-link.current {
                                        background: darken($gray-200, 12%) !important;
                                    }
                                }
                            }
                            &:hover {
                                .menu-link {
                                    &:after {
                                        color: $sl-blue-dark;
                                        -webkit-transform: rotate(-180deg);
                                        transform: rotate(-180deg);
                                    }
                                }
                                .nav-sub-1 {
                                    position: fixed;
                                    left: 0;
                                    display: block;
                                    margin-top: 26px;
                                    background: darken($gray-200, 12%);
                                    width: 100%;
                                    padding-bottom: 15px;
                                    -webkit-box-shadow: 5px 5px 18px -12px rgba(0,0,0,.5);
                                    -moz-box-shadow: 5px 5px 18px -12px rgba(0,0,0,.5);
                                    box-shadow: 5px 5px 18px -12px rgba(0,0,0,.5);

                                    .mega-left {
                                        width: 40%;

                                        @media (min-width: 1200px) {
                                            width: 445px;
                                        }

                                        ul {
                                            li {
                                                margin-right: 50px;

                                                &.parent,
                                                &.not-parent {
                                                    .menu-link {
                                                        background: $sl-grey-light;

                                                        &:after {
                                                            position: relative;
                                                            left: 0;
                                                            display: inline-block;
                                                            content: "\f053";
                                                            font-family: "Font Awesome 5 Free";
                                                            font-weight: 900;
                                                            font-size: 12px;
                                                            margin-left: 8px;
                                                            color: $sl-green;
                                                            transition: all .3s;
                                                        }
                                                        &:hover {
                                                            &:after {
                                                                left: 5px;
                                                                transition: all .3s;
                                                            }
                                                        }
                                                    }
                                                    &:hover {
                                                        .menu-link {
                                                            background: darken($gray-200, 20%);
                                                            transition: all .3s;

                                                            &:after {
                                                                color: $sl-blue-dark;
                                                            }
                                                        }
                                                    }
                                                }
                                                &.not-parent {
                                                    .menu-link {
                                                        &:after {
                                                            display: none;
                                                        }
                                                    }
                                                }
                                                .menu-link {
                                                    float: none;
                                                    padding: 10px 12px;
                                                    margin-bottom: 12px;
                                                    border-radius: .25rem;
                                                    transition: all .3s;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        li {
                            &:hover {
                                .menu-link {
                                    background-color: darken($gray-200, 12%);
                                    transition: all .4s;
                                }
                            }
                        }
                        .menu-link {
                            float: left;
                            position: relative;
                            top: -16px;
                            display: block;
                            padding: 9px 15px 11px 15px;
                            text-decoration: none;
                            border: none;
                            color: $sl-blue-dark;
                            transition: all .4s;

                            &.home {
                                color: $sl-blue-dark;
                                svg {
                                    width: 17px;
                                    height: 17px;
                                    margin-top: -3px;
                                }
                                span {
                                    display: none;
                                }
                            }
                        }
                        .current {
                            background: lighten($sl-green-light, 20%);

                            .menu-link {
                                background: darken($gray-200, 12%);

                                &.current-inner {
                                    color: $sl-green-dark !important;
                                }
                            }
                        }
                        .search-desktop {
                            position: absolute;
                            top: 0;
                            right: 15px;
                            width: 44px;
                            height: 42px;

                            .menu-link {
                                position: relative;
                                top: 0;
                                svg {
                                    position: relative;
                                    //top: 2px;
                                    color: $sl-blue-dark;
                                    width: 16px;
                                    height: 16px;
                                }
                            }
                        }
                        .nav-sub-1 {
                            background: darken($gray-200, 12%);

                            .parent {
                                &.active {
                                    .nav-sub-2 {
                                        position: absolute;
                                        top: 70px;
                                        left: calc(37% + 22px);
                                        display: inline;
                                        width: calc(50% + 15px);
                                        height: calc(100% - 26px);
                                        margin-top: -56px;
                                        max-width: 700px;
                                        border-left: 2px dotted darken($gray-200, 25%);
                                        z-index: 10;

                                        @media (min-width: 1200px) {
                                            left: inherit;
                                            width: 100%;
                                            margin-left: 410px;
                                        }

                                        .mega-right {
                                            padding-left: 16px;

                                            .section-title {
                                                position: absolute;
                                                top: 2px;
                                            }
                                            ul {
                                                position: relative;
                                                top: 40px;
                                                columns: 2;
                                                -webkit-columns: 2;
                                                -moz-columns: 2;

                                                li {
                                                    -webkit-column-break-inside: avoid;
                                                    page-break-inside: avoid;
                                                    break-inside: avoid;
                                                    margin-right: 0;
                                                    overflow: hidden;

                                                    .menu-link {
                                                        background: darken($gray-200, 20%) !important;

                                                        &:after {
                                                            content: none !important;
                                                        }
                                                        &:hover {
                                                            background: darken($gray-200, 25%) !important;
                                                        }
                                                    }
                                                    &.current:first-of-type {
                                                        .menu-link.view.current-inner {
                                                            color: $sl-blue !important;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .menu-link {
                                position: relative;
                                top: 0;

                                &:after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        /* Mobile */
        @media (max-width: 1024px) {
            font-size: 16px;

            @media (min-width: 1025px) {
                display: none;
            }
            @media (max-width: 575px) {
                font-size: 14px;
            }

            .desktop-only {
                display: none !important;
            }

            nav.slide-out-menu {
                position: fixed;
                left: 0;
                top: 0;
                bottom: 0;
                opacity: 0;
                visibility: hidden;
                background: $white;
                width: 100%;
                max-width: 400px;
                z-index: 10000;
                -webkit-box-shadow: 2px 0 40px -12px rgba(0, 0, 0, 0.75);
                -moz-box-shadow: 2px 0 40px -12px rgba(0, 0, 0, 0.75);
                box-shadow: 2px 0 40px -12px rgba(0, 0, 0, 0.75);
                transition: opacity .3s, visibility 0ms linear .2s, transform .15s;
                transform: translate3d(-400px, 0, 0);
                overflow-y: auto;
                overflow-x: hidden;
                -ms-overflow-style: none;

                &::-webkit-scrollbar {
                    display: none;
                }

                @media (max-width: 575px) {
                    max-width: calc(100% - 65px);
                }

                &.menu-active {
                    transform: translateZ(0);
                    opacity: 1;
                    visibility: visible;
                    transition: opacity .3s, visibility 0ms, transform .45s cubic-bezier(0.075, 0.82, 0.165, 1);
                }

                .menu-panels {
                    overflow: hidden;

                    .nav-sub-1 {
                        li {
                            .menu-link {
                                border-bottom: none;
                            }
                        }
                    }
                    .menu-panel {
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        top: 0;
                        overflow-y: auto;
                        overflow-x: hidden;
                        background: $white;
                        z-index: 1;
                        transition: transform .4s cubic-bezier(.23, 1, .32, 1), visibility 0s linear .4s;
                        visibility: hidden;
                        transform: translateX(-400px);

                        &.is-active {
                            visibility: visible;
                            transition: transform .4s cubic-bezier(.23, 1, .32, 1), visibility 0s;
                            transform: translateX(0);
                        }
                        &.menu-panel.is-active {
                            width: calc(100% - 15px);
                            -webkit-box-shadow: 10px 0 19px -15px rgba(0, 0, 0, 0.75);
                            -moz-box-shadow: 10px 0 19px -15px rgba(0, 0, 0, 0.75);
                            box-shadow: 10px 0 19px -15px rgba(0, 0, 0, 0.75);

                            &.menu-panel.is-active {
                                width: calc(100% - 15px);
                            }
                        }
                    }
                    .menu-link {
                        position: relative;
                        text-align: left;
                        width: 100%;
                        display: block;
                        padding: 16px 25px 16px 20px;
                        background: transparent;
                        margin: 0;
                        border: none;
                        color: $sl-blue-dark;
                        font-weight: 400;
                        text-decoration: none;

                        svg[data-icon="home"] {
                            height: 12px;
                            margin-right: 5px;
                            margin-top: -3px;
                        }

                        &:after {
                            position: absolute;
                            right: 20px;
                            top: 18px;
                            content: "\f054";
                            font-family: "Font Awesome 5 Free";
                            font-weight: 900;
                            color: $sl-blue;
                        }
                        &.menu-header {
                            padding: 17px 25px 16px 45px;
                            color: $white;
                            background: $gray-700;

                            &:before {
                                position: absolute;
                                left: 20px;
                                top: 16px;
                                content: "\f053";
                                font-family: "Font Awesome 5 Free";
                                font-weight: 900;
                                font-size: 16px;
                                color: $white;
                            }

                            &:after {
                                content: none;
                            }
                        }
                        &.view, &.home {
                            &:after {
                                content: none;
                            }

                            a {
                                &:after {
                                    content: none;
                                }
                            }
                        }
                        &.section-header {
                            color: $white;
                            background: $sl-green-dark !important;

                            &:after {
                                color: $white;
                            }

                            a {
                                color: $white;
                            }
                        }
                        &.home {
                            .fas {
                                color: $gray-700;
                                margin-right: 5px;
                            }
                        }
                    }
                    ul {
                        margin: 0;
                        padding: 0;
                        list-style: none;

                        li {
                            border-bottom: 1px solid darken($gray-200, 12%);

                            a {
                                width: 100%;
                                display: block;
                                text-decoration: none;
                                color: $sl-blue;
                            }
                            &.phone {
                                border-bottom: none;
                                a {
                                    color: $white;
                                    font-weight: 400;
                                    &:after {
                                        position: absolute;
                                        right: 20px;
                                        top: 15px;
                                        font-family: "Font Awesome 5 Free";
                                        font-size: 16px;
                                        font-weight: 900;
                                        color: $white;
                                    }
                                }
                            }
                            &.phone {
                                background: $sl-green-dark;

                                .menu-link {
                                    &:after {
                                        color: $white;
                                        content: "\f095";
                                    }
                                }
                            }
                            &.search-desktop {
                                display: none;
                            }
                        }
                    }
                    .current {
                        background: darken($gray-200, 12%) !important;
                    }
                }
                .mobile-menu-bottom {
                    form {
                        width: 100%;

                        input {
                            width: calc(100% - 60px);
                            height: 57px;
                            padding: 16px 25px 16px 20px;
                            color: $gray-700;
                            border: none;
                            border-top: 1px solid $sl-blue;
                            border-bottom: 1px solid $sl-blue;
                            border-radius: 0;

                            &:focus, &:focus-within {
                                border: none;
                                border-top: 1px solid $sl-blue;
                                border-bottom: 1px solid $sl-blue;
                            }
                        }
                        button {
                            width: 60px;
                            height: 57px;
                            border-radius: 0;

                            .fas {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }
}
