/* Colours */

/* Brand 1 */
$sl-green-dark:             #00828a;
$sl-green:                  #3dbcb7;
$sl-green-light:            #84e0dc;

/* Brand 2 */
$sl-blue-dark:              #0e334c;
$sl-blue:                   #19538c;
$sl-blue-light:             #4d7aa2;

/* Brand 3 */
$sl-yellow-dark:            #d16f3c;
$sl-yellow:                 #efa643;
$sl-yellow-light:           #e9b779;

/* Brand 4 */
$sl-red-dark:               #5b081e;
$sl-red:                    #830f2e;
$sl-red-light:              #ac2f54;

/* Others */
$sl-grey-dark:              #d5d5d5;
$sl-grey:                   #e5e4e4;
$sl-grey-light:             #f1f1f1;

$black:                     #212121;
$white:                     #FFFFFF;

$primary:                   $sl-green;
$secondary:                 $sl-blue;
$info:                      $sl-yellow;
$danger:                    $sl-red;
$warning:                   $sl-red-light;

$link-color:                $sl-green;
$link-hover-color:          $sl-green-dark;

$link-decoration:           none !default;
$link-hover-decoration:     none !default;

$zindex-modal-backdrop:     111010 !default;
$zindex-modal:              111020 !default;
