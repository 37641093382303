/* Builder Blocks - Stats Box */
.stats-box {
    position: relative;
    overflow: hidden;

    .bg-image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
        opacity: .25;
    }
    .stat {
        span {
            color: $gray-700;
            font-weight: 700;
        }
    }
}
.stats-box.brand-1-bg {
    h3 span {
        color: $gray-700;
    }
}
.stats-box.brand-2-bg {
    h3 span {
        color: $sl-green;
    }
}

@media (max-width: 575px) {
    .stats-box {
        .bg-image {
            img {
                left: 0;
                width: 115%;
            }
        }
    }
}
