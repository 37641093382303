/* Alerts */

/* Defaults */
section {
    &.content-white , &.content-grey {
        .alert {
            display: block;
            width: 100%;
        }
    }
}

/* Styling */
.alert-primary {
    color: $sl-green-dark;
    background-color: $sl-green-dark;
    border-color: $sl-green-dark;

    a {
        text-decoration: underline;
        color: inherit !important;
        &:hover {
            opacity: 0.6;
        }
    }
    .close {
        color: $sl-green-dark;
        transition: .5s all;
        &:hover {
            color: $sl-green-dark;
            opacity: 0.6;
            transition: .5s all;
        }
    }
}
.alert-secondary {
    color: $sl-blue-dark;
    background-color: $sl-blue-light;
    border-color: $sl-blue-light;

    a {
        text-decoration: underline;
        color: inherit !important;
        &:hover {
            opacity: 0.6;
        }
    }
    .close {
        color: $sl-blue-dark;
        transition: .5s all;
        &:hover {
            color: $sl-blue-dark;
            opacity: 0.6;
            transition: .5s all;
        }
    }
}
.alert-success {
    color: $sl-green-dark;
    background-color: $sl-green-dark;
    border-color: $sl-green-dark;

    a {
        text-decoration: underline;
        color: inherit !important;
        &:hover {
            opacity: 0.6;
        }
    }
    .close {
        color: $sl-green-dark;
        transition: .5s all;
        &:hover {
            color: $sl-green-dark;
            opacity: 0.6;
            transition: .5s all;
        }
    }
}
.alert-danger {
    color: $sl-red-dark;
    background-color: $sl-blue-light;
    border-color: $sl-blue-light;

    a {
        text-decoration: underline;
        color: inherit !important;
        &:hover {
            opacity: 0.6;
        }
    }
    .close {
        color: $sl-red-dark;
        transition: .5s all;
        &:hover {
            color: $sl-red-dark;
            opacity: 0.6;
            transition: .5s all;
        }
    }
}
.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #fff3cd;;

    a {
        text-decoration: underline;
        color: inherit !important;
        &:hover {
            opacity: 0.6;
        }
    }
    .close {
        color: #856404;
        transition: .5s all;
        &:hover {
            color: #856404;
            opacity: 0.6;
            transition: .5s all;
        }
    }
}
.alert-info {
    color: $sl-green-dark;
    background-color: $sl-green-dark;
    border-color: $sl-green-dark;

    a {
        text-decoration: underline;
        color: inherit !important;
        &:hover {
            opacity: 0.6;
        }
    }
    .close {
        color: $sl-green-dark;
        transition: .5s all;
        &:hover {
            color: $sl-green-dark;
            opacity: 0.6;
            transition: .5s all;
        }
    }
}
.alert-light {
    color: $gray-700;
    background-color: $white;
    border-color: $white;

    a {
        text-decoration: underline;
        color: inherit !important;
        &:hover {
            opacity: 0.6;
        }
    }
    .close {
        color: $gray-900;
        transition: .5s all;
        &:hover {
            color: $gray-900;
            opacity: 0.6;
            transition: .5s all;
        }
    }
}
.alert-dark {
    color: $gray-900;
    background-color: $gray-200;
    border-color: $gray-200;

    a {
        text-decoration: underline;
        color: inherit !important;
        &:hover {
            opacity: 0.6;
        }
    }
    .close {
        color: $gray-900;
        transition: .5s all;
        &:hover {
            color: $gray-900;
            opacity: 0.6;
            transition: .5s all;
        }
    }
}
