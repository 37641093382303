/* Builder Blocks - Customer story quote basic */
.customer-story-quote-basic {
    padding-bottom: 60px !important;

    .content {
        padding: 0 15px;
        text-align: left !important;
    }
    h3 {
        font-size: 1.5em;
        margin: 1.5rem 0 0 34px;
    }
    blockquote {
        padding-bottom: 0 !important;
        p {
            color: $white;
        }
        &:after {
            bottom: -24px !important;
        }
    }
    .customer-story-btns {
        display: inline-block;
        position: relative;
        bottom: -15px;
        padding: 0 15px;
        margin-left: 20px;
    }
}
@media (max-width: 1100px) {
    .customer-story-quote-basic {
        .customer-story-btns {
            display: block;
            padding: 0;

            .btn {
                float: none !important;
                width: calc(100% - 72px);
                margin-left: 15px;
            }
        }
    }
}
@media (max-width: 767px) {
    .customer-story-quote-basic {
        .content {
            padding-left: 15px;
        }
    }
}
