/* Builder Blocks - Callback form */
.call-back-form-block {
    .call-back-form {
        clear: both;
    }
    h4, .lead {
        color: $white;

        @media (max-width: 480px) {
            font-size: 1.3rem !important;
        }
        @media (max-width: 375px) {
            font-size: 1.2rem !important;
        }
    }
    .fas {
        color: $white;
    }
    a {
        color: $white !important;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
    .form-control {
        background: $white;
        border: 1px solid $white;
    }
    label {
        font-size: 14px;
        font-weight: 700;
    }
    .builder-hr-half {
        width: 20%;
    }
    .call-back-form-notice {
        text-align: left !important;
    }
    .hr-divider {
        position: absolute;
        left: 15px;
        width: calc(100% - 30px);
        border-top: 1px solid $white;
    }
    .more-info {
        span.lead {
            font-size: 24px;
            line-height: 1.3;
        }
    }
    @media (max-width: 575px) {
        margin: 0 auto;
    }
}
