/* Sidebars */

aside#sidebar {
    /* Default sidebar */
    section#sidebar-default {
        top: 170px;
        min-height: 35vh;
        margin-bottom: 50px;

        &.categories-sidebar {
            a {
                font-size: 17px;
            }
            li:last-child {
                margin-bottom: 0;
            }
        }
        @media (max-width: 1024px) and (max-height: 768px) {
            top: 140px;
        }
        @media (max-width: 991px) {
            margin-bottom: 40px;

            .ctas {
                padding: 0 !important;
            }
        }
        @media (max-width: 575px) {
            .ctas {
                padding: 0 15px !important;
            }
        }
    }
    .sidebar-container {
        color: $gray-700;

        a {
            text-decoration: none !important;
        }

        ul {
            li {
                ul {
                    margin-left: 0 !important;
                }
            }
            li:last-child {
                margin-bottom: 8px !important;
            }
        }
        @media (max-width: 575px) {
            margin: 0 auto;
            border: 0;
        }
        .sidebar-item-heading {
            &:before {
                position: absolute;
                top: 35px;
                right: 12px;
                content: "";
                width: 5px;
                height: 20px;
                background: $sl-green-dark;
                z-index: 10;
            }
            &:after {
                position: absolute;
                right: 8px;
                top: 53px;
                content: "";
                width: 13px;
                height: 13px;
                background: $sl-green-dark;
                border-radius: 100%;
                z-index: 10;
            }
        }
        .sidebar-item-title {
            background: $sl-green-dark;
            color: $white;
            padding: 15px;
            position: relative;
            border-top-left-radius: .25rem;
            border-top-right-radius: .25rem;
            font-weight: 700;

            &:before {
                top: 54px;
            }
            &:after {
                top: 66px;
            }
        }
        .sidebar-item-first, .sidebar-item-sub {
            min-height: 44px;

            &:before {
                background: $sl-green;
            }
            &:after {
                background: $sl-green;
            }
        }
        .sidebar-item-first {
            background: $sl-green;
            padding: 15px;
            position: relative;

            a {
                color: $gray-700;

                &:hover {
                    color: $gray-900;
                }
            }
        }
        .sidebar-item-sub {
            background: $gray-200;
            padding: 15px;
            position: relative;

            a {
                color: $sl-green-dark;

                &:hover {
                    color: darken($sl-green-dark, 10%);
                }
            }
            &:before {
                background: $gray-200;
            }
            &:after {
                background: $gray-200;
            }
            &:nth-of-type(4) {
                background: $gray-200;

                &:before {
                    background: $gray-200;
                }
                &:after {
                    background: $gray-200;
                }
            }
        }
        .sidebar-item {
            font-size: 16px;
            padding: .6rem 1.2rem;

            a {
                text-decoration: underline;
            }
        }
        .sidebar-item.sidebar-item-active {
            display: -webkit-box;
            display: -moz-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            padding-left: 1.7rem;

            a:before,
            span:before {
                position: relative;
                left: -8px;
                content: "\f061";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
            }
        }
    }
}
