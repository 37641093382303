/* Builder Blocks - Cells */
.cell-content {
    a span {
        font-weight: 700 !important;
    }
    p:last-child {
        margin-bottom: 0;
    }
    table {
        margin-bottom: 1rem !important;
        width: 100% !important;
        height: auto !important;
        overflow-x: auto;
        border-collapse: collapse;
        border-radius: 6px;
        border-style: hidden;
        box-shadow: 0 1px 8px 2px #dadada;

        thead {
            background-color: $gray-700;
            border-color: $gray-700 $gray-200;
            color: $white;
            font-weight: 700;

            tr {
                th, td {
                    color: $white;
                    font-weight: 700;
                    background-color: $gray-700;
                    border-top: 1px solid $gray-700;
                    border-right: 1px solid $gray-200;
                    border-bottom: 1px solid $gray-700;
                    border-left: 1px solid $gray-200;
                    padding: 5px 10px;
                    border-color: $gray-700 darken($gray-700, 11%);

                    &:first-child {
                        border-left: 1px solid $gray-700;
                        border-top-left-radius: 6px;
                    }
                    &:last-child {
                        border-right: 1px solid $gray-700;
                        border-top-right-radius: 6px;
                    }
                }
            }
        }
        tbody {
            tr {
                border-bottom: 1px solid $gray-200;
                vertical-align: top;

                th, td {
                    padding: 5px 10px;
                    border-right: 1px solid $gray-200;
                }
                td {
                    &:first-child {
                        border-left: 1px solid $gray-200;
                        font-weight: 700;
                    }
                }
                &:nth-child(even) {
                    background-color: $gray-200;
                }
            }
        }
    }
    @media (max-width: 400px) {
        table {
            font-size: 14px;
        }
        th,td {
            padding: 3px 3px 3px 5px;
        }
    }

    @media (max-width: 991px) {
        table {
            display: block;
        }
    }

    .cookie-data {
        font-size: 12px;
    }

    table.opening-times {
        tbody {
            tr {
                background: none !important;
                border: none !important;

                th, td {
                    color: $gray-700 !important;
                    background: none !important;
                    border: none !important;
                    padding: 5px 0 !important;
                }
                td.time {
                    padding-left: 10px !important;
                    font-weight: 400 !important;
                }
            }
        }
    }
}
