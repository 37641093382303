/* Builder Blocks - USP List */
.usp-list {
    .usp-set {
        .title {
            margin-top: -10px;
        }
        .usp {
            min-height: 46px;
            margin-bottom: 6px;

            .icon {
                position: absolute;
                top: 0;
                background: $info;
                border-radius: 100%;
                width: 40px;
                height: 40px;
                padding: 8px;

                .fas {
                    color: $white;
                    font-size: 20px;
                }
            }
            .content {
                padding: 0 10px 0 48px;
            }
        }
        hr {
            margin-top: 10px !important;
            margin-bottom: 1.5rem !important;

            &:last-child {
                margin-bottom: 0 !important;
            }
        }
    }
    &.boxed {
        padding: 22px 24px 14px 24px;
        border-radius: 6px;
        background: $gray-200;
        -webkit-box-shadow: 0 0 18px -5px rgba(0, 0, 0, .5);
        -moz-box-shadow: 0 0 18px -5px rgba(0, 0, 0, .5);
        box-shadow: 0 0 18px -5px rgba(0, 0, 0, .5);

        .title {
            margin-top: 0;
        }
        hr {
            display: none;
        }
    }
}
.content-grey {
    .usp-list {
        &.boxed {
            background: $white;
        }
        &:not(.boxed) {
            padding-top: 15px;
        }
    }
}
