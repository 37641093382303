/* Customer stories */
.customer-stories-custom-cards-fixed {
    .card-wrapper {
        .card {
            display: inline-block;
            margin-bottom: 2.25rem;

            &.fixed-height {
                min-height: 525px;
                max-height: 525px;

                @media (max-width: 767px) {
                    min-height: 100% !important;
                    max-height: 100% !important;

                    .card-text {
                        margin-bottom: 10px;
                    }
                }
            }

            @media (max-width: 767px) {
                min-height: auto;
            }
        }

        .card-title {
            transition: 0.3s all;

            .card-body {
                .name {
                    transition: 0.3s all;

                    &:hover {
                        color: $sl-green-dark !important;
                        transition: 0.3s all;
                    }
                }
            }
            .cta {
                position: absolute;
                bottom: 0;
                width: 100%;
            }

            &:hover {
                transform: scale(1.01);
                transition: 0.3s all;
                @extend .shadow;
            }
        }
    }
}
.customer-stories-custom-cards {
    .card-columns {
        -webkit-column-count: 3 !important;
        -moz-column-count: 3 !important;
        column-count: 3 !important;
        -webkit-column-gap: 30px;
        -moz-column-gap: 30px;
        column-gap: 30px;
        orphans: 1;
        widows: 1;
    }
    .card-wrapper {
        .card {
            display: inline-block;
            margin-bottom: 2.25rem;
        }

        .card-title {
            transition: 0.3s all;

            .card-body {
                .name {
                    transition: 0.3s all;

                    &:hover {
                        color: $sl-green-dark !important;
                        transition: 0.3s all;
                    }
                }

                .card-text {

                    &.fixed-height {
                        min-height: 165px;
                        max-height: 165px;
                        overflow: hidden;
                    }

                    @media (max-width: 767px) {
                        min-height: auto;
                    }
                }
            }

            &:hover {
                transform: scale(1.01);
                transition: 0.3s all;
                @extend .shadow;
            }
        }
    }

    @media (max-width: 991px) {
        .card-columns {
            -webkit-column-count: 2 !important;
            -moz-column-count: 2 !important;
            column-count: 2 !important;
            -webkit-column-gap: 30px;
            -moz-column-gap: 30px;
            column-gap: 30px;
            orphans: 1;
            widows: 1;
        }
    }

    @media (max-width: 767px) {
        .card-columns {
            -webkit-column-count: 1 !important;
            -moz-column-count: 1 !important;
            column-count: 1 !important;
            -webkit-column-gap: 0;
            -moz-column-gap: 0;
            column-gap: 0;
            orphans: 1;
            widows: 1;
        }
    }
}
.customer-story-content {
    blockquote {
        position: relative;
        padding: 1.5rem 3.4rem;
        margin-bottom: 10px;

        &:before, &:after {
            position: absolute;
            opacity: .23;
            font-size: 2.7em;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            color: $black;
            z-index: 0;
        }

        &:before {
            top: 0;
            left: 0;
            content: "\f10d";
        }
        &:after {
            bottom: 0;
            right: 0;
            content: "\f10e";
        }
    }
}
.customer-story-media {
    padding: 16px 16px 8px 16px;
    margin-bottom: 40px;

    blockquote {
        h3 {
            position: relative;
            z-index: 10;
        }
        &:before, &:after {
            position: absolute;
            opacity: .23;
            font-size: 2.7em;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            color: $black;
            z-index: 0;
        }

        &:before {
            top: 0 !important;
            left: 0 !important;
        }
        &:after {
            bottom: 0 !important;
            right: 0 !important;
        }
    }

    .gallery-area {
        .owl-theme {
            .owl-stage-outer {
                overflow: hidden;
                @extend .rounded;
            }
            .owl-nav {
                position: absolute;
                top: calc(50% - 40px);
                left: 50%;
                bottom: -8px;
                font-size: 40px;
                height: 0px;
                width: 90%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                z-index: 0;

                button {
                    opacity: 1;
                }
                .fas {
                    color: $white;

                    &:hover {
                        color: $gray-200;
                    }
                }
                .owl-prev {
                    left: 3px;
                }
                .owl-next {
                    right: 3px;
                }

                @media (max-widtH: 767px) {
                    top: calc(50% - 32px);
                    font-size: 32px;
                }
                @media (max-widtH: 375px) {
                    top: calc(50% - 24px);
                    font-size: 24px;
                }
            }
        }
        .owl-thumbs {
            .owl-thumb-item {
                .thumb {
                    width: 100%;
                    max-width: 100%;

                    @media (max-width: 767px) {
                        .owl-thumbs .owl-thumb-item .thumb {
                            max-width: 100%;
                        }
                        @media (max-width: 575px) {
                            .owl-thumbs .owl-thumb-item .thumb {
                                max-width: 100%;
                            }
                            @media (max-width: 375px) {
                                .owl-thumbs .owl-thumb-item .thumb {
                                    max-width: 100% !important;
                                }
                            }

                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
    .content {
        h4 {
            margin-bottom: 0;
        }
        blockquote {
            padding: 1.5rem 0.3rem !important;
            margin-bottom: 0;

            p {
                font-size: 20px;
                line-height: 26px;
                margin-bottom: 0;
            }
        }
    }
}
