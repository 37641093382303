/* Builder Blocks - Trustpilots */

/* Carousel */
.trustpilot-carousel {
    .tp-logo {
        width: 145px;
        max-width: 145px;
    }
    p {
        color: $white;
        margin-bottom: 0;
    }
    .owl-theme {
        .owl-dots {
            position: absolute;
            right: 0;
            bottom: 3px;

            @media (max-width: 575px) {
                position: relative;
            }

        }
        .owl-nav {
            font-size: 24px;

            .owl-prev {
                left: 0;
                margin: 0;
            }
            .owl-next {
                right: 0;
                margin: 0;
            }
        }
    }
    &.brand-1-bg {
        h4, h5 {
            color: $gray-700;
        }
    }
    &.brand-2-bg {
        h4, h5 {
            color: $sl-green;
        }
    }
}

/* Micro */
.trustpilot-micro {
    @media (min-width: 1200px) {
        left: -365px;
    }
    @media (max-width: 1199px) {
        left: -275px;
    }
    @media (max-width: 991px) {
        left: -160px;
    }
    @media (max-width: 767px) {
        left: -70px;
    }
    @media (max-width: 575px) {
        left: 0;
    }
}

/* Block */
.trustpilot-block {
    hr.tp-accordion {
        margin: 20px 0;
    }
    hr.tp-accordion:first-child {
        margin-top: 0;
        margin-bottom: 20px;
    }
    hr.tp-accordion:last-child {
        margin-top: 20px;
        margin-bottom: 0;
    }
}
