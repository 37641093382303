@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: local('Lato'),
    url('//cdn.surveyorlocal.co.uk/media/assets/fonts/lato-v17-latin-regular.woff2') format('woff2'),
    url('//cdn.surveyorlocal.co.uk/media/assets/fonts/lato-v17-latin-regular.woff') format('woff');
}
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: local('Lato Black'),
    url('//cdn.surveyorlocal.co.uk/media/assets/fonts/lato-v17-latin-700.woff2') format('woff2'),
    url('//cdn.surveyorlocal.co.uk/media/assets/fonts/lato-v17-latin-700.woff') format('woff');
}

/* Text */
body {
    font-size: 18px;
    ::selection {
        color: $white;
        background: $gray-700;
    }
    ::-moz-selection {
        color: $white;
        background: $gray-700;
    }
}
h1, h2, h3, h4, h5, h6 {
    strong {
        font-weight: 700;
    }
}
h2 {
    margin-bottom: 1rem !important;

    @media (max-width: 420px) {
        font-size: 1.4rem !important;
    }
}
h6 {
    font-size: 18px;
}
b, strong {
    font-weight: 700;
}
.text-dark {
    color: $sl-blue-dark !important;
}
.text-normal {
    color: $gray-700 !important;
}
.emphasis {
    font-weight: 700;
}

.tel-fix {
    white-space: nowrap;
}

/* Links */
a {
    transition: .25s all;
    color: $link-color;
    text-decoration: $link-decoration;
    background-color: transparent; // Remove the gray background on active links in IE 10.

    &:hover {
        transition: .25s all;
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
    }
    &:active {
        color: $sl-green-dark;
        text-decoration: none;
        transition: .25s all;
    }
    &:focus {
        color: $sl-green-dark;
        text-decoration: none;
        transition: .25s all;
    }
}
.fas {
    color: $gray-700;
    font-weight: 700;
    transition: .25s all;
}
.fab {
    color: $gray-700;
    font-weight: 700;
    transition: .25s all;
}
a.li {
    &:hover {
        color: $gray-900;
        text-decoration: none;
        transition: .25s all;
    }
    &:active {
        color: $gray-900;
        text-decoration: none;
        transition: .25s all;
    }
    &:focus {
        color: $gray-900;
        text-decoration: none;
        transition: .25s all;
    }
}
a.text-primary {
    color: $sl-green;
    &:hover {
        color: $sl-green-dark !important;
    }
    &:active {
        color: $sl-green-dark !important;
    }
    &:focus {
        color: $sl-green-dark !important;
    }
}
a.text-secondary {
    color: $sl-blue;
    &:hover {
        color: $sl-blue-dark !important;
    }
    &:active {
        color: $sl-blue-dark !important;
    }
    &:focus {
        color: $sl-blue-dark !important;
    }
}
a.text-info {
    color: $sl-yellow;
    &:hover {
        color: $sl-yellow-dark !important;
    }
    &:active {
        color: $sl-yellow-dark !important;
    }
    &:focus {
        color: $sl-yellow-dark !important;
    }
}
a.text-warning {
    color: $sl-red-light;
    &:hover {
        color: $sl-red !important;
    }
    &:active {
        color: $sl-red !important;
    }
    &:focus {
        color: $sl-red !important;
    }
}
a.text-danger {
    color: $sl-red;
    &:hover {
        color: $sl-red-dark !important;
    }
    &:active {
        color: $sl-red-dark !important;
    }
    &:focus {
        color: $sl-red-dark !important;
    }
}
a.text-white {
    color: $white;
    &:hover {
        opacity: 0.6;
    }
    &:active {
        opacity: 0.6;
    }
    &:focus {
        opacity: 0.6;
    }
}
.fancy-link {
    color: $sl-green;
    text-decoration: none;
    font-weight: 700;

    span {
        text-decoration: none;
    }
    &:before {
        position: relative;
        left: 0;
        content: "\f054";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        margin-right: 7px;
        text-decoration: none;
        transition: all .25s;
    }
    &:hover {
        color: $sl-green-dark;
        text-decoration: none;

        &:before {
            color: $sl-green-dark;
            left: 3px;
        }
    }
}
.fancy-link-alt {
    color: $sl-green;
    text-decoration: none;
    font-weight: 700;

    span {
        text-decoration: none;
    }
    &:after {
        position: relative;
        top: 1px;
        left: 0;
        content: "\f054";
        font-size: 16px;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        margin-left: 7px;
        text-decoration: none;
        transition: all .25s;
    }
    &:hover {
        color: $sl-green-dark;
        text-decoration: none;

        &:after {
            color: $sl-green-dark;
            left: 3px;
        }
    }
}

/* List resets */
ul {
    list-style-type: none;
    padding-left: 0;
}

/* Pagination resets */
.pagination {
    .page-item {
        a {
            text-decoration: none !important;
            padding: 6px 10px;
        }

        &.active a {
            color: $white !important;
            background-color: $sl-green;
            border-color: $sl-green;

            &:hover {
                cursor: text;
                background-color: $sl-green;
                border-color: $sl-green;
                opacity: 1;
            }
        }
    }
}
