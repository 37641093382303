/* Builder Blocks - Social box */
.social-box {
    .icons {
        max-width: 170px;

        .social-icon {
            display: inline-block;
            margin: 7px 12px 4px 3px;
            text-align: center;
            width: 54px;
            height: 54px;
            padding: 9px;
            border-radius: 50%;

            @media (max-width: 375px) {
                margin: 7px 5px 4px 3px;
            }

            &:hover {
                background: $white !important;
            }
            .icon {
                color: $white;
                font-size: 30px;
                line-height: 38px;
            }
        }
        @media (max-width: 767px) {
            text-align: left !important;
            padding: 0 !important;
            max-width: 100%;
        }
    }
}
