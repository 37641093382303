/* Trustpilot widgets */

/* Inline */
.tp-wrapper-inline {
    position: absolute;
    bottom: 25px;

    @media (max-width: 1024px) {
        position: relative;
        bottom: 0;
        padding-bottom: 25px;
    }

    .tp-widget-inline {
        a {
            color: $white;

            &:hover {
                opacity: .75;
            }
        }
        .tp-widget-logo {
            display: inline-block;
            width: 130px;
            height: auto;
        }
        .tp-widget-stars {
            position: relative;
            top: 3px;
            display: inline-block;
            width: 155px;
            height: auto;
            margin: 0 15px;

            @media (max-width: 350px) {
                display: block;
                margin: 10px 0 0 0;
            }
        }
        .widget-info {
            position: relative;
            top: 8px;
            display: inline-block;

            &.black {
                color: $gray-900;

                span {
                    color: $sl-green;
                    text-decoration: none;
                }
                @media (max-width: 359px) {
                    display: none;
                }
            }
        }
    }
}

/* Sidebar */
.tp-wrapper-side {
    .tp-widget-side {
        font-size: 14px;

        .tp-widget-stars {
            margin: 0 auto;
            width: 155px;

            svg {
                height: 28px;
            }
        }
        .tp-widget-businessinfo {
            font-size: 12px;
            color: $gray-900;
            display: inline-block;

            .tp-widget-businessinfo__trustscore, .tp-widget-businessinfo__total {
                float: left;
            }
            .tp-widget-businessinfo__trustscore::after {
                position: relative;
                top: -1px;
                content: "|";
                margin: 0 6px;
                font-weight: 700;
            }
        }
        .tp-widget-logo {
            max-width: 110px;
            margin: 0 auto;

            .tp-logo-wrapper {
                position: relative;
                height: 0;
                width: 100%;
                padding: 5px 0 25% 0;
            }
        }
        @media (max-width: 820px) {
            margin: 0 auto;
            left: 35px;

            .tp-widget-businessinfo__trustscore::after,
            .tp-widget-businessinfo__total {
                clear: none !important;
                display: inline-block;
            }
        }
        @media (min-width: 768px) and (max-width: 991px) {
            .tp-widget-stars {
                .tp-stars {
                    .tp-stars-wrapper {
                        svg {
                            width: 75%;
                        }
                    }
                }
            }
        }
        @media (max-width: 767px) {
            left: 0;

            .tp-widget-stars {
                .tp-stars {
                    .tp-stars-wrapper {
                        svg {
                            width: 100%;
                            margin: 5px 0;
                        }
                    }
                }
            }
        }
        @media (max-width: 575px) {
            max-width: 215px;
        }
    }
}
