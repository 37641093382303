/* Builder Blocks - Jump links */
.jump-links-block {
    .jump-link {
        span {
            position: relative;
            left: 28px;
            display: inline-block;
            width: 90%;
            text-indent: -1.3em;

            a {
                padding-left: 23px;
            }
        }
        &:before {
            position: absolute;
            top: -1px;
            content: "\f322";
            font-family: "Font Awesome 5 Free";
            font-size: 18px;
            font-weight: 900;
            color: $sl-green-dark;
            margin-right: 10px;
            transition: all .25s;
        }
        &:hover {
            &:before {
                top: 2px;
                transition: all .25s;
            }
        }
    }
}
