/* News */
section.content-white, section.content-grey {
    #results-info {
        border-top: 1px solid $gray-200;
        border-bottom: 1px solid $gray-200;
        padding: 30px 0;
        margin-bottom: 25px;

        p {
            margin: 0;
        }
    }
    #categories-select {
        .container {
            position: relative;
            top: -30px;
            color: $white;
            background-color: $gray-700;

            span {
                font-size: 18px;
                font-weight: 700;
                line-height: 38px;
            }
            .btn {

            }
        }
        optgroup {
            color: #cecece;
            font-weight: 300;
            option {
                color: #575757;
            }
        }
    }
    .news {
        .news-area {
            .news-post {
                .news-post-header {
                    &.on-page {
                        .details {
                            width: 100%;
                            border-bottom: 1px solid $gray-200;
                        }
                    }
                    .post-thumbnail {
                        height: 180px;
                        background-size: cover;
                        background-position: center;
                        transition: all .25s;

                        @media (max-width: 767px) {
                            height: 250px;
                        }

                        &:hover {
                            opacity: .6;
                            transition: all .25s;
                        }
                    }
                    .details {
                        font-size: 14px;
                    }
                    .news-banner {
                        width: 100%;
                    }
                }
                &.card {
                    background: $gray-100;
                }
            }
            .news-content {
                margin: 0 -15px;
            }
            .details-divider {
                color: $sl-green;
            }
            .category-icon {
                margin-right: 4px;
            }
        }
        .news-share-area {
            font-size: 14px;
            border-top: 1px solid $gray-200;

            .news-share-icon {
                position: relative;
                top: 4px;
                font-size: 26px;
                margin-right: 8px;

                .fab {
                    background: $sl-green;
                    color: $white;
                    padding: 8px;
                    border-radius: 50%;
                    width: 38px;
                    height: 38px;
                    text-align: center;
                    font-size: 20px;
                    line-height: 24px;

                    &:hover {
                        background: $sl-green-dark;
                        transition: all .2s;
                    }
                }
                &:first-child {
                    margin-left: 6px;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        .news-pagination {
            margin: 0 auto;
            font-size: 14px;

            ul.pagination {
                display: inline-block;

                li.page-item {
                    display: inline;
                    left: 0;
                    text-indent: 0 !important;

                    a {
                        float: left;
                    }
                    .page-link {
                        &:hover {
                            color: $sl-green-dark;
                        }
                    }
                    &.active {
                        a {
                            background: $sl-green-dark;
                        }
                    }
                    &:before {
                        content: none !important;
                    }
                }
            }
        }
    }
    .related-news {
        background: $white;
        height: 100%;

        .news-post {
            height: 100%;

            .news-post-header {
                .post-thumbnail {
                    height: 120px;
                    background-size: cover;
                    background-position: center;
                    transition: all .25s;

                    &:hover {
                        opacity: .6;
                        transition: all .25s;
                    }
                }
                .details {
                    font-size: 14px;

                    .details-divider {
                        color: $sl-green;
                    }
                    .category-icon {
                        margin-right: 4px;
                    }
                }
            }

            .news-post-body {
                margin-bottom: 60px;
            }

            .read-more {
                bottom: 20px;
                position: absolute;
            }
        }
        @media (min-width: 992px) {
            &:nth-child(1), &:nth-child(2) {
                border-right: 1px solid $gray-200;
            }
        }
    }
}
