/* Builder Blocks - Icon Image Cards */
.icon-image-cards {
    a {
        text-decoration: none !important;

        .card {
            min-height: 100%;
            border: none;
            transition: 0.3s all;

            .card-img-top {
                &.icon {
                    margin: 0 auto;
                    width: 160px !important;
                    transition: all .2s ease-in-out;
                }
            }
            .card-body {
                h5 {
                    text-decoration: none;
                    transition: all .25s;
                }
                p {
                    color: $gray-700 !important;
                    font-weight: 400 !important;
                    margin-bottom: 0;
                }
            }
            button {
                position: relative;
                bottom: 25px;
                margin: 0 auto;
                width: calc(100% - 30px);
                /*@extend .btn-dark;*/
            }

            &:hover {
                transform: scale(1.01);
                transition: 0.3s all;
            }
        }
        &:hover {
            .card {
                .card-img-top {
                    &.icon {
                        transform: scale(1.07);
                    }
                }
                .card-body {
                    h5 {
                        color: $gray-700 !important;
                        transition: all .25s;
                    }
                }
            }
        }
    }
    &.content-white {
        a {
            .card {
                background: $gray-200;
            }
        }
    }
}
