/* Builder Blocks - Info Callout */
.info-callout {
    padding-bottom: 35px !important;
    overflow: hidden;

    .icon {
        width: 44px;
        float: left;
        margin-right: 15px;
    }
}

@media (max-width: 420px) {
    .info-callout {

        .icon {
            float: none;
            margin-bottom: 20px;
        }
    }
}
