/* Builder Blocks - Customer story quote */
.customer-story-quote {
    img {
        width: 100%;
    }
    .content {
        padding: 0 15px;
        text-align: left !important;
    }
    h3 {
        font-size: 1.5em;
    }
    .person {
        font-size: 20px;
    }
    .youtube {
        margin-top: -65px;
        height: auto;
    }
    .customer-story-btns {
        margin-top: 15px;

        .btn-watch {
            margin-right: 10px;
        }
    }
    .modal-content {
        .video-container {
            padding-top: 0;
        }
    }
}
@media (max-width: 1100px) {
    .customer-story-quote {
        .customer-story-btns {
            display: block;
            padding: 0;

            .btn {
                float: none !important;
                width: calc(100% - 72px);
            }
            .btn-watch {
                margin-right: 0;
                margin-bottom: 15px;
            }
        }
    }
}
@media (max-width: 767px) {
    .customer-story-quote {
        .content {
            padding-left: 15px;
        }
    }
}

@media (max-width: 767px) {
    .customer-story-quote {
        .customer-story-btns {
            .btn {
                width: 100%;

                &:last-child {
                    margin-bottom: 20px !important;
                }
            }
        }
    }
}
