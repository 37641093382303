/* Page templates */

/* Compare surveys */
.compare-surveys-page {
    table {
        border-collapse: collapse;
        margin: 0;
        padding: 0;
        width: 100%;
        table-layout: fixed;
        border: none;
        border-radius: .25rem;
        box-shadow: 0 .5rem 1rem rgba(33,33,33,.15);
        overflow: hidden;

        thead tr th,
        tbody tr td {
            padding: 10px 12px;
            text-align: center;
            background-color: $sl-grey-light;
            border: 1px solid $sl-grey-dark;

            &:nth-of-type(1) {
                width: 50%;
                text-align: left;
            }
        }
        tbody {
            tr {
                td {
                    transition: .1s ease-in-out;

                    i {
                        font-size: 22px;

                        &.fa-times {
                            opacity: .25;
                        }
                    }
                }
                &:nth-of-type(odd) {
                    td {
                        background: $sl-grey !important;
                    }
                }
                &:not(:last-of-type) {
                    &:hover {
                        td {
                            background: lighten($sl-grey-dark, 3%) !important;
                            transition: .15s ease-in-out;
                        }
                    }
                }
            }
        }
        &:hover {
            cursor: default;
        }
        @media (max-width: 991px) {
            tbody {
                tr {
                    &.instruct-btns {
                        display: none;
                    }
                }
            }
        }
        @media (max-width: 767px) {
            thead tr th {
                height: 190px;

                .vertical {
                    position: relative;
                    top: -40px;
                    display: block;
                    white-space: nowrap;
                    transform: rotate(90deg);
                }
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    padding: 0;
                }
            }
        }
        @media (max-width: 375px) {
            thead tr th {
                .vertical {
                    top: -55px;
                }
            }
        }
    }
}

/* Surveyor index page */
.surveyor-index {
    span.surveyor-anchor {
        display: block;
        position: relative;
        top: -245px;
        visibility: hidden;
    }
    .btn-group {
        border-radius: .25rem;
        overflow: hidden;

        .btn {
            border-radius: 0 !important;
            padding: 7px 10px !important;

            @media (max-width: 991px) {
                width: 7.69%;
            }
            @media (max-width: 480px) {
                width: 11%;
            }
            @media (max-width: 319px) {
                width: 13%;
            }
        }
    }
    .lead {
        font-weight: 700;
    }
    h3 {
        display: inline;
    }
    .surveyor-cat {
        background: $gray-200;
        padding: 8px 15px 10px 15px;
        margin-top: 3rem;

        &:first-of-type {
            margin-top: 0;
        }
    }
    ul.surveyor-list {
        columns: 3;
        -webkit-columns: 3;
        -moz-columns: 3;

        li {
            &:before {
                transition: all .35s;
            }
            &:hover {
                &:before {
                    left: 8px;
                    transition: all .2s;
                }
            }
            &.blank {
                &:before {
                    content: none;
                }
            }
        }
        &:last-child {
            margin-bottom: 3rem;
        }
        @media (max-width: 991px) {
            columns: 2;
            -webkit-columns: 2;
            -moz-columns: 2;
        }
        @media (max-width: 575px) {
            columns: 1;
            -webkit-columns: 1;
            -moz-columns: 1;
        }
    }
}

/* Surveyor pages */
.surveyor-page {
    .quotebuilder,
    .quickquote {
        border: 0 !important;
        padding-bottom: 20px !important;
    }
    .surveyor-usps {
        .card {
            position: relative;
            top: -45px;
            background: $gray-200;
            font-size: 16px;
            text-align: left;
            margin-bottom: -5px;
            min-height: 100%;
            border: none;
            overflow: hidden;

            @media (max-width: 1199px) {
                min-height: 240px;
            }
            @media (max-width: 991px) {
                min-height: 0;
                margin-bottom: 20px;
            }
            @media (max-width: 575px) {
                text-align: center;
            }

            .card-body {
                padding: 0;

                .icon {
                    float: left;
                    width: 25%;
                    padding: 20px 0 0 20px;

                    @media (max-width: 991px) {
                        width: 15%;

                        img {
                            max-width: 65px;
                        }
                    }
                    @media (max-width: 575px) {
                        padding-left: 0;
                        width: 100%;
                    }
                }

                .content {
                    float: left;
                    width: 75%;
                    padding: 20px;

                    @media (max-width: 991px) {
                        width: 85%;
                    }
                    @media (max-width: 575px) {
                        width: 100%;
                    }
                    h5 {
                        margin-top: 0;
                        margin-bottom: 10px;
                        color: $sl-green;
                        font-size: 18px;
                        text-align: left !important;

                        @media (max-width: 575px) {
                            text-align: center !important;
                        }
                    }
                    p {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

/* Glossary page */
.glossary-index {
    .btn-group {
        border-radius: .25rem;
        overflow: hidden;

        .btn {
            border-radius: 0 !important;
            padding: 7px 12px !important;
            width: 7.69%;

            @media (max-width: 480px) {
                width: 11%;
            }
            @media (max-width: 319px) {
                width: 13%;
            }
        }
    }
}

/* Glossary page */
.glossary-index {
    .btn-group {
        border-radius: .25rem;
        overflow: hidden;

        .btn {
            border-radius: 0 !important;
            padding: 7px 12px !important;
            width: 7.69%;

            @media (max-width: 480px) {
                width: 11%;
            }
            @media (max-width: 319px) {
                width: 13%;
            }
        }
    }
}

/* Contact page */
.contact-page {
    .item {
        .icon {
            i {
                color: $white;
                font-size: 20px;
                text-align: center;
                width: 48px;
                height: 48px;
                padding: 14px;
                margin-right: 6px;
                background: $sl-green-dark;
                border-radius: 100%;
            }
            @media (max-width: 374px) {
                display: none;
            }
        }
        .content {
            @media (max-width: 767px) {
                padding-left: 35px;
            }
            @media (max-width: 480px) {
                padding-left: 45px;
            }
            @media (max-width: 374px) {
                padding-left: 15px;
            }
            .opening-times {
                font-size: 16px;

                .time {
                    padding-left: 10px;
                }
            }
        }
    }
    .form {
        form {
            fieldset {
                textarea {
                    height: 170px;
                }
            }
        }
    }
    hr {
        border-top: 1px dotted $gray-400;
    }
}

/* Thank you page */
.thank-you-page {
    .item {
        .icon {
            width: 65px;

            i {
                color: $white;
                font-size: 20px;
                text-align: center;
                width: 48px;
                height: 48px;
                padding: 14px;
                margin-right: 6px;
                background: $sl-green-dark;
                border-radius: 100%;
            }
            @media (max-width: 374px) {
                display: none;
            }
        }
        .content {
            width: auto;

            h4 {
                font-size: 18px;
            }
            a {
                font-size: 1.5rem;
            }
            .opening-times {
                .time {
                    padding-left: 10px;
                }
            }
        }
    }
    hr {
        border-top: 1px dotted $gray-400;
    }
}

/* Error pages */
.error-page {
    overflow: hidden;
    padding-bottom: 50px;

    @media (max-width: 575px) {
        margin-top: 30px;
    }
    .error-page-text {
        position: relative;
        z-index: 10;

        h2 {
            font-size: 100px;
            font-weight: 700;
            z-index: 10;

            @media (max-width: 420px) {
                font-size: 60px !important;
            }
        }
        h4 {
            font-size: 34px;
            z-index: 10;
        }
    }
    .error-page-bg {
        position: absolute;
        top: 45%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        z-index: 0;

        span {
            color: $gray-200;
            font-size: 250px;
            font-weight: 700;
            opacity: 0.35;

            @media (max-width: 767px) {
                font-size: 260px;
            }
        }
    }
    .btn {
        width: 235px;
    }
}
