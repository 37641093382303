/* Builder Blocks - Global styles */
.paco-editor-area {

    /* Defaults */
    section.page-section {
        padding: 25px 0;
        margin-bottom: 0;

        h1, h2, h3, h4, h5, h6 {
            color: $sl-green;
        }

        &:first-of-type {
            padding-top: 0 !important;
            margin-top: 0 !important;

            h2 {
                color: $sl-blue !important;
            }
        }
        .title-content {
            h2, h3, h4 {
                margin-bottom: 0 !important;
            }
        }
        &.open-accordion {
            @media (min-width: 769px) {
                .accordion-hr {
                    margin: 5px 0 -16px 0;
                }
            }
            @media (max-width: 768px) {
                padding: 0;
                margin-bottom: 15px;
                background: $white !important;

                .builder-accordion {
                    .builder-accordion-title-wrapper {
                        padding: 5px 0;
                    }
                }
                .page-section {
                    background: $white !important;
                }
            }
        }
        .modal {
            top: 180px;

            @media (min-width: 992px) {
                .modal-dialog {
                    max-width: 50% !important;
                }
            }
            @media (min-width: 769px) {
                .modal-dialog {
                    max-width: 60%;
                }
            }
            @media (max-width: 768px) {
                .modal-dialog {
                    max-width: 90%;
                }
            }
            @media (max-width: 767px) {
                top: 200px;
            }
            @media (max-width: 575px) {
                top: 205px;
                .modal-dialog {
                    max-width: 100%;
                }
            }
        }
        .opening-times {
            width: auto !important;
            box-shadow: none !important;
        }
    }
    blockquote {
        padding: 1.5rem 3.4rem;
        margin-bottom: 10px;

        &:before, &:after {
            position: absolute;
            opacity: .175;
            font-size: 2.7em;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            color: $black;
            z-index: 0;
        }

        &:before {
            top: 0;
            left: 0;
            content: "\f10d";
        }
        &:after {
            bottom: 0;
            right: 0;
            content: "\f10e";
        }
    }
    img {
        max-width: 100%;
    }
    &.compress {
        @media (max-width: 575px) {
            margin-bottom: 0;
        }
    }
    p a {
        text-decoration: none;
    }
    ul {
        margin-left: 15px;

        ul {
            margin-left: 25px;
        }
    }
    .text-link {
        text-decoration: none !important;

        &:after {
            content: " \f054";
            font-family: "Font Awesome 5 Free";
            font-size: 14px;
            font-weight: 900;
        }
    }
    .content {
        float: left;
        .tagline {
            -webkit-transition: all .3s ease-in-out;
            -moz-transition: all .3s ease-in-out;
            -o-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
        }
        a.tagline {
            text-decoration: none !important;
            -webkit-transition: all .3s ease-in-out;
            -moz-transition: all .3s ease-in-out;
            -o-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
            &:hover {
                opacity: 0.6;
                -webkit-transition: all .3s ease-in-out;
                -moz-transition: all .3s ease-in-out;
                -o-transition: all .3s ease-in-out;
                transition: all .3s ease-in-out;
            }
        }
    }
    blockquote {
        position: relative;
        border: 0;
        color: $gray-700;
        p {
            position: relative;
            z-index: 10;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .quote-content {
        clear: both;

        h3, blockquote {
            margin-top: 15px;
        }
    }
    .quote-marks {
        .fa-quote-left, .fa-quote-right {
            font-size: 2.7em;
            font-weight: 700;
            opacity: .25;
            color: $gray-500;
        }
        .fa-quote-right {
            clear: both;
        }
    }
    .cell-content {
        img {
            @extend .rounded;
        }
    }

    /* Dynamic background options */
    .brand-1-dark-bg {
        color: $white;
        background: $sl-green-dark;

        p, h1, h2, h3, h4, h5, h6, .tagline {
            color: $white !important;
        }
        p a {
            color: $white;
            &:hover {
                color: $white !important;
                opacity: 0.6;
            }
        }
        blockquote {
            color: $white;
        }
        .quote-marks {
            .fa-quote-left, .fa-quote-right {
                color: $white;
            }
        }
        .tagline {
            color: $white;

            a {
                color: $white;

                &:hover {
                    opacity: 0.6;
                }
            }
        }
        .profile-pic {
            background-color: rgba(255, 255, 255, .2);
        }
        a.text-link {
            @extend a.text-white;
        }
        ul, ol {
            li {
                &:before {
                    color: $sl-green;
                }
            }
            ul, ol {
                li {
                    &:before {
                        color: $sl-green;
                    }
                }
            }

            &.secondary, &.info {
                li {
                    &:before {
                        color: $sl-green;
                    }
                }
                ul, ol {
                    li {
                        &:before {
                            color: $sl-green;
                        }
                    }
                }
            }
        }
        .btn {
            @extend .btn-light;
        }
        .social-icon {
            background: $sl-green;

            &:hover {
                .icon {
                    color: $sl-green-dark;
                }
            }
        }
        .modal {
            .modal-body {
                p {
                    color: $gray-700;
                }
            }
            .modal-footer {
                .btn {
                    @extend .btn-primary;
                }
            }
        }
        hr.builder-hr-half, hr.builder-hr-full {
            @extend .brand-2;
        }
        .owl-theme {
            .owl-dots {
                .owl-dot {
                    span {
                        background: $white;
                    }
                }
            }
        }
    }
    .brand-1-bg {
        color: $white ;
        background: $sl-green;

        p, h1, h2, h3, h4, h5, h6, .tagline {
            color: $white !important;
        }
        p a {
            color: $white;
            &:hover {
                color: $white !important;
                opacity: 0.6;
            }
        }
        blockquote {
            color: $white;
        }
        .quote-marks {
            .fa-quote-left, .fa-quote-right {
                color: $white;
            }
        }
        .tagline {
            color: $white;

            a {
                color: $white;

                &:hover {
                    opacity: 0.6;
                }
            }
        }
        .profile-pic {
            background-color: rgba(255, 255, 255, .2);
        }
        a.text-link {
            @extend a.text-white;
        }
        ul, ol {
            li {
                &:before {
                    color: $gray-700;
                }
            }
            ul, ol {
                li {
                    &:before {
                        color: $gray-700;
                    }
                }
            }

            &.secondary, &.info {
                li {
                    &:before {
                        color: $gray-700;
                    }
                }
                ul, ol {
                    li {
                        &:before {
                            color: $gray-700;
                        }
                    }
                }
            }
        }
        .btn {
            @extend .btn-dark;
        }
        .social-icon {
            background: $gray-700;

            &:hover {
                .icon {
                    color: $sl-green-dark;
                }
            }
        }
        .modal {
            .modal-body {
                p {
                    color: $gray-700;
                }
            }
        }
        hr.builder-hr-half, hr.builder-hr-full {
            @extend .brand-2;
        }
        .owl-theme {
            .owl-dots {
                .owl-dot {
                    span {
                        background: $white;
                    }
                }
            }
        }
    }
    .brand-2-bg {
        color: $white;
        background: $sl-blue;

        p, h1, h2, h3, h4, h5, h6, .tagline {
            color: $white;
        }
        p a {
            color: $white;
            &:hover {
                color: $white !important;
                opacity: 0.6;
            }
        }
        blockquote {
            color: $white;
        }
        .quote-marks {
            .fa-quote-left, .fa-quote-right {
                color: $white;
            }
        }
        .tagline {
            color: $white;

            a {
                color: $white;

                &:hover {
                    opacity: 0.6;
                }
            }
        }
        .profile-pic {
            background-color: rgba(255, 255, 255, .2);
        }
        a.text-link {
            @extend a.text-white;
        }
        ul, ol {
            li {
                &:before {
                    color: $sl-green-dark;
                }
            }
            ul, ol {
                li {
                    &:before {
                        color: $sl-green-dark;
                    }
                }
            }

            &.secondary, &.info {
                li {
                    &:before {
                        color: $sl-green-dark;
                    }
                }
                ul, ol {
                    li {
                        &:before {
                            color: $sl-green-dark;
                        }
                    }
                }
            }
        }
        .btn {
            @extend .btn-primary;
        }
        .social-icon {
            background: $sl-green-dark;

            &:hover {
                .icon {
                    color: $gray-700;
                }
            }
        }
        .modal {
            .modal-body {
                p {
                    color: $gray-700;
                }
            }
        }
        hr.builder-hr-half, hr.builder-hr-full {
            @extend .brand-1;
        }
        .owl-theme {
            .owl-dots {
                .owl-dot {
                    span {
                        background: $white;
                    }
                }
            }
        }
    }
    .grey-bg {
        color: $gray-700;
        background: $gray-200;

        h1, h2, h3, h4, h5, h6, .tagline {
            color: $gray-700;
        }
        p {
            color: $gray-700;
        }
        .profile-pic {
            background-color: rgba(87, 87, 87, .2);
        }
        a.text-link {
            @extend a.text-primary;
        }
        .btn {
            @extend .btn-primary;
        }
        .btn.btn-alt {
            @extend .btn-secondary;
        }
        hr.builder-hr-half, hr.builder-hr-full {
            border-top: 5px solid $gray-400;
        }
        .owl-theme {
            .owl-dots {
                .owl-dot {
                    span {
                        background: $sl-green-dark;
                    }
                }
            }
        }
    }
    .white-bg {
        color: $gray-700;
        background: $white;

        @media (max-width: 575px) {
            border-top: 2px solid $gray-200;
            border-bottom: 2px solid $gray-200;
        }

        h1, h2, h3, h4, h5, h6, .tagline {
            color: $sl-green-dark;
        }
        p {
            color: $gray-700;
        }
        .profile-pic {
            background-color: rgba(87, 87, 87, .2);
        }
        a.text-link {
            @extend a.text-secondary;
        }
        .btn {
            @extend .btn-primary;
        }
        .btn.btn-alt {
            @extend .btn-primary;
        }
        hr.builder-hr-half, hr.builder-hr-full {
            border-top: 5px solid $gray-200;
        }
        .owl-theme {
            .owl-dots {
                .owl-dot {
                    span {
                        background: $gray-700;
                    }
                }
            }
        }
    }

    /* Required at end of all builder blocks */
    .clear {
        clear: both;
    }
}
