/* Buttons */

/* Resets */
.btn {
    color: $white;
    text-decoration: none;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 700;
    text-transform: none !important;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    &.btn-sm {
        padding: .25rem .8rem;
    }
    &:hover {
        color: $white;
    }
    &.disabled {
        color: $white;
        background-color: $gray-400;
        border-color: $gray-400;
        opacity: 0.5;
        cursor: not-allowed;

        &.left:before {
            right: 0 !important;
        }
        &.right:after {
            left: 0 !important;
        }
        &:hover, &:active, &:focus {
            background-color: $gray-400 !important;
            border-color: $gray-400 !important;
            box-shadow: none !important;
        }
    }
}

/* Arrow master styles */
.btn.left {
    &:before {
        content: "\f053";
        font-family: "Font Awesome 5 Free";
        font-size: 14px;
        font-weight: 900;
        margin-right: 7px;
        position: relative;
        right: 0;
        transition: all .25s;
    }
    &:hover {
        &:before {
            right: 6px;
        }
    }
}
.btn.right {
    &:after {
        content: "\f054";
        font-family: "Font Awesome 5 Free";
        font-size: 14px;
        font-weight: 900;
        margin-left: 7px;
        position: relative;
        left: 0;
        transition: all .25s;
    }
    &:hover {
        &:after {
            left: 6px;
        }
    }
}

/* The buttons */
/******** Primary ********/
.btn-primary {
    color: $white;
    background-color: $sl-green;
    border-color: $sl-green;
    &:hover {
        color: $white;
        background-color: $sl-green-dark;
        border-color: $sl-green-dark;
    }
    &:active,&:focus {
        color: $white;
        background-color: $sl-green-dark;
        border-color: $sl-green-dark;
        box-shadow: 0 0 0 0.2rem rgba(61,188,183, 0.5);
    }
}
.btn-outline-primary {
    color: $sl-green;
    background-color: transparent;
    border-color: $sl-green;
    &:hover {
        color: $white;
        background-color: $sl-green;
        border-color: $sl-green;
    }
    &:active,&:focus {
        color: $white;
        background-color: $sl-green;
        border-color: $sl-green;
        box-shadow: 0 0 0 0.2rem rgba(61,188,183, 0.5);
    }
}
.btn-outline-primary.left {
    &:before {
        color: $sl-green;
    }
    &:hover {
        &:before {
            color: $white;
        }
    }
}
.btn-outline-primary.right {
    &:after {
        color: $sl-green;
    }
    &:hover {
        &:after {
            color: $white;
        }
    }
}
/******** Secondary ********/
.btn-secondary {
    color: $white;
    background-color: $sl-blue;
    border-color: $sl-blue;
    &:hover {
        color: $white;
        background-color: $sl-blue-dark;
        border-color: $sl-blue-dark;
    }
    &:active,&:focus {
        color: $white;
        background-color: $sl-blue-dark;
        border-color: $sl-blue-dark;
        box-shadow: 0 0 0 0.2rem rgba(20,46,71, 0.5);
    }
}
.btn-outline-secondary {
    color: $sl-blue;
    background-color: transparent;
    border-color: $sl-blue;
    &:hover {
        color: $white;
        background-color: $sl-blue;
        border-color: $sl-blue;
    }
    &:active,&:focus {
        color: $white;
        background-color: $sl-blue;
        border-color: $sl-blue;
        box-shadow: 0 0 0 0.2rem rgba(20,46,71, 0.5);
    }
}

.btn-outline-secondary.left {
    &:before {
        color: $sl-blue;
    }
    &:hover {
        &:before {
            color: $white;
        }
    }
}
.btn-outline-secondary.right {
    &:after {
        color: $sl-blue;
    }
    &:hover {
        &:after {
            color: $white;
        }
    }
}
/******** Tertiary  ********/
.btn-tertiary {
    color: $white;
    background-color: $sl-blue-dark;
    border-color: $sl-blue-dark;
    &:hover {
        color: $white;
        background-color: $gray-900;
        border-color: $gray-900;
    }
    &:active,&:focus {
        color: $white;
        background-color: $gray-900;
        border-color: $gray-900;
        box-shadow: 0 0 0 0.2rem rgba(20,46,71, 0.5);
    }
}
.btn-outline-tertiary {
    color: $sl-blue-dark;
    background-color: transparent;
    border-color: $sl-blue-dark;
    &:hover {
        color: $white;
        background-color: $sl-blue-dark;
        border-color: $sl-blue-dark;
    }
    &:active,&:focus {
        color: $white;
        background-color: $sl-blue-dark;
        border-color: $sl-blue-dark;
        box-shadow: 0 0 0 0.2rem rgba(20,46,71, 0.5);
    }
}

.btn-outline-tertiary.left {
    &:before {
        color: $sl-blue-dark;
    }
    &:hover {
        &:before {
            color: $white;
        }
    }
}
.btn-outline-tertiary.right {
    &:after {
        color: $sl-blue-dark;
    }
    &:hover {
        &:after {
            color: $white;
        }
    }
}
/******** Info ********/
.btn-info {
    color: $white;
    background-color: $sl-yellow;
    border-color: $sl-yellow;
    &:hover {
        color: $white;
        background-color: $sl-yellow-dark;
        border-color: $sl-yellow-dark;
    }
    &:active,&:focus {
        color: $white;
        background-color: $sl-yellow-dark;
        border-color: $sl-yellow-dark;
        box-shadow: 0 0 0 0.2rem rgba(239,166,67, 0.3);
    }
}
.btn-outline-info {
    color: $sl-yellow;
    background-color: transparent;
    border-color: $sl-yellow;
    &:hover {
        color: $white;
        background-color: $sl-yellow;
        border-color: $sl-yellow;
    }
    &:active,&:focus {
        color: $white;
        background-color: $sl-yellow;
        border-color: $sl-yellow;
        box-shadow: 0 0 0 0.2rem rgba(239,166,67, 0.3);
    }
}
.btn-outline-info.left {
    &:before {
        color: $sl-yellow;
    }
    &:hover {
        &:before {
            color: $white;
        }
    }
}
.btn-outline-info.right {
    &:after {
        color: $sl-yellow;
    }
    &:hover {
        &:after {
            color: $white;
        }
    }
}
/******** Warning ********/
.btn-warning {
    color: $white;
    background-color: $sl-blue-light;
    border-color: $sl-blue-light;
    &:hover {
        color: $white;
        background-color: $sl-blue;
        border-color: $sl-blue;
    }
    &:active,&:focus {
        color: $white;
        background-color: $sl-blue;
        border-color: $sl-blue;
        box-shadow: 0 0 0 0.2rem rgba(65,110,150, 0.3);
    }
}
.btn-outline-warning {
    color: $sl-blue-light;
    background-color: transparent;
    border-color: $sl-blue-light;
    &:hover {
        color: $white;
        background-color: $sl-blue-light;
        border-color: $sl-blue-light;
    }
    &:active,&:focus {
        color: $white;
        background-color: $sl-blue-light;
        border-color: $sl-blue-light;
        box-shadow: 0 0 0 0.2rem rgba(65,110,150, 0.3);
    }
}
.btn-outline-warning.left {
    &:before {
        color: $sl-blue-light;
    }
    &:hover {
        &:before {
            color: $white;
        }
    }
}
.btn-outline-warning.right {
    &:after {
        color: $sl-blue-light;
    }
    &:hover {
        &:after {
            color: $white;
        }
    }
}
/******** Danger ********/
.btn-danger {
    color: $white !important;
    background-color: $sl-red !important;
    border-color: $sl-red !important;
    &:hover {
        color: $white !important;
        background-color: $sl-red-dark !important;
        border-color: $sl-red-dark !important;
    }
    &:active,&:focus {
        color: $white !important;
        background-color: $sl-red-dark !important;
        border-color: $sl-red-dark !important;
        box-shadow: 0 0 0 0.2rem rgba(172,47,84, 0.3) !important;
    }
}
.btn-outline-danger {
    color: $sl-red !important;
    background-color: transparent !important;
    border-color: $sl-red !important;
    &:hover {
        color: $white !important;
        background-color: $sl-red !important;
        border-color: $sl-red !important;
    }
    &:active,&:focus {
        color: $white !important;
        background-color: $sl-red !important;
        border-color: $sl-red !important;
        box-shadow: 0 0 0 0.2rem rgba(220,53,69, 0.3) !important;
    }
}
.btn-outline-danger.left {
    &:before {
        color: $sl-red;
    }
    &:hover {
        &:before {
            color: $white;
        }
    }
}
.btn-outline-danger.right {
    &:after {
        color: $sl-red;
    }
    &:hover {
        &:after {
            color: $white;
        }
    }
}
/******** Light ********/
.btn-light {
    color: $gray-700;
    background-color: $gray-200;
    border-color: $gray-200;
    &:hover {
        color: $gray-900;
        background-color: $gray-400;
        border-color: $gray-400;
    }
    &:active,&:focus {
        color: $gray-900;
        background-color: $gray-400;
        border-color: $gray-400;
        box-shadow: 0 0 0 0.2rem rgba(227, 227, 227, 0.5);
    }
}
.btn-outline-light {
    color: $gray-400;
    background-color: transparent;
    border-color: $gray-200;
    &:hover {
        color: $gray-700;
        background-color: $gray-200;
        border-color: $gray-200;
    }
    &:active,&:focus {
        color: $gray-700;
        background-color: $gray-200;
        border-color: $gray-200;
        box-shadow: 0 0 0 0.2rem rgba(227, 227, 227, 0.5);
    }
}
.btn-outline-light.left {
    &:before {
        color: $gray-400;
    }
    &:hover {
        &:before {
            color: $gray-700;
        }
    }
}
.btn-outline-light.right {
    &:after {
        color: $gray-400;
    }
    &:hover {
        &:after {
            color: $gray-700;
        }
    }
}
/******** Dark ********/
.btn-dark {
    color: $white;
    background-color: $gray-700;
    border-color: $gray-700;
    &:hover {
        color: $white;
        background-color: $gray-800;
        border-color: $gray-800;
    }
    &:active,&:focus {
        color: $white;
        background-color: $gray-800;
        border-color: $gray-800;
        box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
    }
}
.btn-outline-dark {
    color: $gray-700;
    background-color: transparent;
    border-color: $gray-900;
    &:hover {
        color: $white;
        background-color: $gray-700;
        border-color: $gray-700;
    }
    &:active,&:focus {
        color: $white;
        background-color: $gray-700;
        border-color: $gray-700;
        box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
    }
}
.btn-outline-dark.left {
    &:before {
        color: $gray-700;
    }
    &:hover {
        &:before {
            color: $white;
        }
    }
}
.btn-outline-dark.right {
    &:after {
        color: $gray-700;
    }
    &:hover {
        &:after {
            color: $white;
        }
    }
}

/* Special buttons */
.btn.below {
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    &:after {
        margin-left: 8px;
        display: inline-block;
        transition: -webkit-transform .2s ease-in-out;
        transition: transform .2s ease-in-out;
    }
    &:hover, &:active, &:focus {
        &:after {
            left: 0;
            transform: rotate(90deg);
        }
    }
}

.btn-watch,
.btn-team,
.btn-linkedin,
.btn-search,
.btn-home,
.btn-mail,
.btn-archive,
.btn-contact,
.btn-phone {
    &:after {
        margin-left: 8px;
        font-size: 14px;
    }
}
.btn-watch {
    &:after {
        content: "\f144";
        font-family: "Font Awesome 5 Free";
        font-weight: 500;
    }
}
.btn-team {
    &:after {
        content: "\f2bd";
        font-family: "Font Awesome 5 Free";
        font-weight: 600;
    }
}
.btn-linkedin {
    &:after {
        content: "\f08c";
        font-family: "Font Awesome 5 Brands";
        font-weight: 500;
    }
}
.btn-search {
    &:after {
        content: "\f002";
        font-family: "Font Awesome 5 Free";
        font-weight: 600;
    }
}
.btn-home {
    &:after {
        content: "\f015";
        font-family: "Font Awesome 5 Free";
        font-weight: 600;
    }
}
.btn-mail {
    &:after {
        content: "\f658";
        font-family: "Font Awesome 5 Free";
        font-weight: 600;
    }
}
.btn-archive {
    &:after {
        content: "\f00a";
        font-family: "Font Awesome 5 Free";
        font-weight: 600;
    }
}
.btn-contact {
    &:after {
        content: "\f086";
        font-family: "Font Awesome 5 Free";
        font-weight: 400;
    }
}
.btn-phone {
    &:after {
        content: "\f095";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
    }
}
.btn-account {
    &:before {
        content: "\f2bd";
        font-family: "Font Awesome 5 Free";
        font-weight: 500;
        margin-right: 8px;
        font-size: 14px;
    }
}
.show-more-action {
    .btn-show-more {
        &:after {
            content: "\f078";
            font-family: "Font Awesome 5 Free";
            font-size: 14px;
            font-weight: 900;
            margin-left: 7px;
            position: relative;
            top: 0;
            transition: all .25s;
        }
        &:hover {
            &:after {
                top: 4px;
            }
        }
        &:active,&:focus {
            color: $white;
        }
    }
    .btn-show-less {
        &:after {
            content: "\f077";
            font-family: "Font Awesome 5 Free";
            font-size: 14px;
            font-weight: 900;
            margin-left: 7px;
            position: relative;
            top: 0;
            transition: all .25s;
        }
        &:hover {
            &:after {
                top: -4px;
            }
        }
        &:active,&:focus {
            color: $white;
        }
    }
}
