/* PPC sections */

main.ppc-default {
    .quotebuilder {

    }
    .why-choose {
        .tp {
            margin-top: 15px;
            margin-bottom: 45px;
        }
        .card {
            background: $gray-700;
            text-align: left;
            margin-bottom: 20px;
            min-height: 182px;
            border: none;
            overflow: hidden;

            @media (max-width: 1199px) {
                min-height: 232px;
            }
            @media (max-width: 991px) {
                min-height: auto;
            }
            @media (max-width: 575px) {
                text-align: center;
            }

            .card-body {
                padding: 0;

                .icon {
                    float: left;
                    width: 25%;
                    padding: 20px 0 0 20px;

                    @media (max-width: 991px) {
                        width: 15%;

                        img {
                            max-width: 65px;
                        }
                    }
                    @media (max-width: 575px) {
                        padding-left: 0;
                        width: 100%;
                    }
                }

                .content {
                    float: left;
                    width: 75%;
                    padding: 20px;

                    @media (max-width: 991px) {
                        width: 85%;
                    }
                    @media (max-width: 575px) {
                        width: 100%;
                    }

                    h5 {
                        margin-top: 0;
                        margin-bottom: 10px;
                        color: $sl-green;
                        font-size: 18px;
                        text-align: left !important;

                        @media (max-width: 575px) {
                            text-align: center !important;
                        }
                    }
                    p {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .location {
        position: relative;
        padding-bottom: 0;
        overflow: hidden;

        @media (max-width: 1199px) {
            padding-bottom: 45px;
        }

        hr.divider {
            position: absolute;
            width: 150px;
            height: 6px;
            margin-top: 10px;
            background: $sl-green;
            border-radius: 3px;
        }
        .surveyors {
            margin-top: 60px;

            .divider {
                margin-right: 12px;
            }
            .a-z {
                overflow-wrap: break-word;

                a {
                    margin-right: 12px;
                    text-decoration: none !important;
                }
            }
            .ldn {
                a {
                    text-decoration: none !important;
                }
            }
        }
        .map {
            img {
                position: relative;
                top: -20px;
                width: 100%;
            }
        }
    }
    .faqs {
        .accordion {
            .faq {
                padding-left: 40px;
                border-bottom: 1px dotted $gray-700;

                &:last-of-type {
                    border-bottom: none;
                }

                button.question {
                    color: $gray-700 !important;
                    font-size: 18px;
                    padding: 15px 0;
                    box-shadow: none !important;
                    text-transform: none !important;

                    &.collapsed {
                        &:before {
                            position: absolute;
                            left: 15px;
                            content: "\f054";
                            font-family: "Font Awesome 5 Free";
                            font-weight: 900;
                            font-size: 18px;
                            text-align: center;
                            color: #4d545a;
                            background: #b4cf7f;
                            width: 30px;
                            height: 30px;
                            padding: 2px 0 1px 1px;
                            border-radius: 100%;
                            transform: rotate(0);
                            transition: transform .25s ease-out;
                        }
                    }

                    &:before {
                        position: absolute;
                        left: 15px;
                        content: "\f054";
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        font-size: 18px;
                        text-align: center;
                        color: #4d545a;
                        background: #b4cf7f;
                        width: 30px;
                        height: 30px;
                        padding: 2px 0 1px 1px;
                        border-radius: 100%;
                        transform: rotate(90deg);
                        margin-top: -4px;
                        transition: transform .25s ease-out;
                    }

                    &:hover {
                        color: $gray-900 !important;
                    }
                }
                .answer {
                    font-size: 16px;
                }
            }
        }
    }
}

main.ppc-alternate, main.ppc-branded {
    section.ppc-header {
        background-image: url('//cdn.national-accident-helpline.co.uk/media/assets/images/ppc-alternate-background.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center !important;

        .intro {
            background: $sl-green-dark;

            a {
                color: $white;
                text-decoration: underline;

                &:hover {
                    color: $white;
                    text-decoration: none;
                }
            }
        }

        .ppc-hero-bullets {
            color: $gray-700;
            background: $white;

            a {
                color: $sl-green-dark !important;

                &:hover {
                    color: $sl-green-dark !important;
                }
            }

            ol {
                li {
                    text-indent: 0;
                    left: 15px;
                    border-bottom: 2px solid $gray-200;
                    padding-bottom: 10px;

                    &:before {
                        position: absolute;
                        left: -35px;
                        background: $sl-blue;
                        color: $white;
                        width: 1.4em;
                        height: 1.3em;
                        font-weight: 700;
                        line-height: 1.3em;
                        text-align: center;
                        padding-top: 1px;
                        border-radius: 3px;
                    }

                    &:last-child {
                        margin-bottom: 0 !important;
                        border-bottom: none !important;
                        padding-bottom: 0 !important;
                    }
                }
            }
        }

        @media (min-width: 1200px) {
            .ppc-call-back-form-boxed, .ppc-hero-bullets {
                height: 435px;
            }
        }
        @media (max-width: 1199px) {
            .ppc-call-back-form-boxed, .ppc-hero-bullets {
                height: 490px;
            }
        }
        @media (max-width: 991px) {
            .ppc-call-back-form-boxed, .ppc-hero-bullets {
                height: 525px;
            }
        }
        @media (max-width: 767px) {
            .ppc-call-back-form-boxed, .ppc-hero-bullets {
                height: auto;
            }
        }
        @media (min-width: 768px) {
            .ppc-header-box-1 {
                padding-left: 0;
            }
            .ppc-header-box-2 {
                padding-right: 0;
            }
        }
        @media (max-width: 767px) {
            .ppc-header-box-1, .ppc-header-box-2 {
                padding: 0;
            }
        }
    }

    section.ppc-content {
        ul {
            font-size: 22px;
            line-height: 32px;

            li {
                margin-bottom: 20px;
                padding-bottom: 20px;
                border-bottom: 1px solid $sl-green-dark;

                &:last-child {
                    border-bottom: none;
                }
            }
        }

        .trustpilot-area {
            height: 100%;
            padding-bottom: 30px;

            .trustpilot-widget {
                height: 100%;

                iframe {
                    height: 100%;
                }
            }

            @media (max-width: 767px) {
                display: none;
            }
        }

        .customer-story-area {
            &.container {
                overflow: hidden;
            }

            .btn {
                float: none;
                margin-right: 20px;

                &:last-child {
                    margin: 0;
                }

                @media (max-width: 767px) {
                    .btn {
                        width: 100%;
                    }
                }
            }

            .video-header {
                background: $sl-blue;
                border-radius: .25rem .25rem 0 0 !important;
            }

            .embed-youtube {
                border-radius: 0 0 .25rem .25rem !important;
            }

            @media (max-width: 767px) {
                .btn {
                    width: 100%;
                    margin-bottom: 15px;
                }
            }
        }
    }

    section.ppc-phone {
        span {
            font-size: 36px;
            line-height: 52px;
            font-weight: 700;
        }

        a {
            text-decoration: underline;

            &:hover {
                opacity: 0.6;
            }
        }
    }

    section.ppc-usps {
        .icons {
            .icon {
                width: 150px;
                max-width: 150px;
                background: $white;
                border: 2px solid $sl-blue;
                border-radius: 50%;
                padding: 15px;
                -webkit-box-shadow: 0 0 18px -5px rgba(0, 0, 0, 0.5);
                -moz-box-shadow: 0 0 18px -5px rgba(0, 0, 0, 0.5);
                box-shadow: 0 0 18px -5px rgba(0, 0, 0, 0.5);
            }
        }
    }

    section.ppc-sectors {
        a {
            text-decoration: none !important;
        }

        .icons {
            a:hover {
                .icon {
                    transform: scale(1.1);
                }

                h5, .fas {
                    color: $sl-green-dark !important;
                    transition: .25s all;
                }

                .fas {
                    left: 10px;
                }
            }

            .icon {
                width: 150px;
                max-width: 150px;
                background: $white;
                border: 2px solid $sl-blue;
                border-radius: 50%;
                padding: 15px;
                -webkit-box-shadow: 0 0 18px -5px rgba(0, 0, 0, 0.5);
                -moz-box-shadow: 0 0 18px -5px rgba(0, 0, 0, 0.5);
                box-shadow: 0 0 18px -5px rgba(0, 0, 0, 0.5);
                transition: all .2s ease-in-out;
            }

            .fas {
                position: relative;
                left: 0;
                color: $sl-blue;
                font-size: 16px;
            }
        }

        .icon-section {
            a:hover {
                .icon {
                    transform: scale(1.1);
                }

                h5 {
                    color: $sl-green !important;
                    transition: .25s all;
                }
            }

            .icon {
                width: 120px;
                max-width: 115px;
                background: $white;
                border: 2px solid $sl-blue;
                border-radius: 50%;
                padding: 15px;
                -webkit-box-shadow: 0 0 18px -5px rgba(0, 0, 0, 0.5);
                -moz-box-shadow: 0 0 18px -5px rgba(0, 0, 0, 0.5);
                box-shadow: 0 0 18px -5px rgba(0, 0, 0, 0.5);
                transition: all .2s ease-in-out;

                &:hover {
                    transform: scale(1.1);
                }
            }

            h5 {
                transition: .25s all;
            }
        }

        .no-win-no-fee {
            a {
                text-decoration: underline !important;
            }
        }
    }

    section.call-back-form-ppc {
        .ppc-hero-bullets {
            color: $gray-700;
            background: $white;

            a {
                color: $sl-green-dark !important;

                &:hover {
                    color: $sl-green-dark;
                    text-decoration: underline;
                    opacity: 1;
                }
            }
        }
    }

    .ppc-hero-image {
        overflow: hidden;
        background-position: 70%;
        background-size: cover;
        background-repeat: no-repeat;

        @media (max-width: 767px) {
            background-position: 70%;
        }
        @media (max-width: 575px) {
            background-position: 55%
        }

        ul.ppc-usp-list {
            position: relative;
            top: 50%;
            left: 50%;
            list-style: none;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

            li {
                width: 60%;
                background: $sl-green-dark;
                text-align: right;
                margin-bottom: 20px;
                padding: 5px 15px 5px 15px;

                .fas {
                    color: $sl-green-dark;
                    margin-right: 4px;
                }

                @media (min-width: 1200px) {
                    width: 50%;
                }
                @media (max-width: 991px) {
                    width: 80%;
                }
            }
        }

        @media (max-width: 767px) {
            height: 310px;

            ul.ppc-usp-list {
                li {
                    width: 50%;
                    text-align: left;
                    padding: 5px 0 5px 30px;
                }
            }
        }
        @media (max-width: 575px) {
            ul.ppc-usp-list {
                li {
                    width: 75%;
                    background: rgba(0, 78, 99, .85);
                }
            }
        }
    }
}
