/* Breadcrumbs */
.breadcrumb-bar {
    font-size: 14px;
    background: rgba(255,255,255,.35);
    margin-top: 20px;
    display: table;

    a {
        color: $white;
        font-weight: 700;
        opacity: 1;
        transition: all .3s;

        &:hover {
            text-decoration: none;
            opacity: .6;
            transition: all .3s;
        }
    }
    ul {
        position: relative;
        left: 0;
        display: inline;
        li {
            display: inline;
            &:before {
                content: "\f054";
                font-family: "Font Awesome 5 Free";
                font-size: 13px;
                font-weight: 900;
                padding: 0 8px;
            }
            &.active {
                font-weight: 400;
            }
        }
    }
    @media (max-width: 767px) {
        display: none;
    }
}
