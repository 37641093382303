/*
 *  Owl Carousel - Theme
 */

$dot-default-color:     $sl-green;

$dot-width:				10px;
$dot-height:			10px;
$dot-rounded:			50%;
$dot-margin: 			4px;

$nav-font-size: 		14px;
$nav-margin: 			5px;
$nav-padding: 			4px 7px;
$nav-enabled-opacity:   1;
$nav-disabled-opacity: 	0.5;

.owl-theme {
    .owl-nav {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 24px;
        height: 90px;
        width: 100%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

        .owl-prev {
            left: 15px;
        }
        .owl-next {
            right: 15px;
        }
        .fa {
            color: $white;
        }
        button {
            position: absolute;
            opacity: 0.6;
            transition: 0.3s all;

            &:hover {
                opacity: 1;
                transition: 0.3s all;
            }

            &:focus {
                outline: none !important;
            }
        }

        [class*='owl-'] {
            color: $white;
            font-size: $nav-font-size;
            margin: $nav-margin;
            padding: $nav-padding;
            background: $gray-200;
            display: inline-block;
            cursor: pointer;
            border-radius: 3px;

            &:hover {
                opacity: $nav-disabled-opacity;
                text-decoration: none;
            }
        }

        .disabled {
            opacity: $nav-disabled-opacity;
            cursor: default;
        }
    }

    .owl-nav.disabled + .owl-dots {
        margin-top: 10px;
    }

    .owl-dots {
        margin-top: 15px;
        text-align: center;
        -webkit-tap-highlight-color: transparent;

        button:focus {
            outline: none !important;
        }

        .owl-dot {
            display: inline-block;
            zoom: 1;
            *display: inline;

            span {
                width: $dot-width;
                height: $dot-height;
                margin: $dot-margin;
                background: $dot-default-color;
                display: block;
                border-radius: $dot-rounded;
                transition: all 200ms ease;
                -webkit-backface-visibility: visible;
            }

            &.active {
                transform: scale(1.4);
                transition: all 200ms ease;
            }

            &:not(.active) {
                opacity: $nav-disabled-opacity;
                transition: 0.3s all;

                &:hover {
                    transform: scale(1.2);
                    transition: 0.3s all;
                }
            }
            &:hover {
                span {
                    opacity: $nav-enabled-opacity;
                }
            }
        }
    }

    .owl-stage-outer {
        .owl-stage {
            .owl-item {
                border-radius: .25rem;
                overflow: hidden;
            }
        }
    }
}

.owl-thumbs {
    padding: 0 36px;
    width: 100%;
    white-space: nowrap;
    display: table;

    .owl-thumb-item {
        vertical-align: middle;
        padding: 6px;
        display: table-cell;

        &:not(.active) {
            opacity: .5;
        }

        .thumb {
            max-width: 50px;

            @media (max-width: 767px) {
                max-width: 45px;
            }
            @media (max-width: 575px) {
                max-width: 40px;
            }
            @media (max-width: 375px) {
                max-width: 35px;
            }
            @media (max-width: 320px) {
                max-width: 28px;
            }
        }
    }
}
