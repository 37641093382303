/* Builder Blocks - Generic quote */
.generic-quote {
    img {
        width: 100%;
    }
    h3 {
        font-size: 1.5em;
    }
    .person {
        font-size: 20px;
        font-weight: 700;
    }
    .job-title {
        font-size: 16px;
    }
}
@media (max-width: 767px) {
    .generic-quote {
        .content {
            clear: both;
            padding-left: 15px;
        }
        .image {
            float: none !important;
        }
    }
}
