/* CUSTOM CSS */

/* October loading bar */
.stripe-loading-indicator .stripe,
.stripe-loading-indicator .stripe-loaded {
    background: $sl-green !important;
}

/* Fix for lazy load images */
img:not([src]) {
    visibility: hidden;
}

/* IE overrides */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .tp-wrapper-inline {
        position: relative;
        bottom: -10px;
    }
}

/* Lity lightbox overrides */
.lity {
    z-index: 999999;

    .lity-close {
        right: 18px;
        top: 18px;
        font-family: $font-family-sans-serif;
        font-weight: 700;
        width: 44px;
        height: 44px;
        font-size: 50px;
        transition: .3s all;

        &:hover {
            opacity: .6;
            transition: .3s all;
        }
    }
}

button[type=submit].oc-loading {
    font-size: 0;
    &:hover:after {
        left: 0!important;
    }
    &:after {
        content: " ";
        font-size: medium;
        width: 25px;
        height: 25px;
        animation: spinner .4s linear infinite;
        border-color: #fff transparent transparent #fff;
        border-style: solid;
        border-width: 2px;
        border-radius: 50%;
        box-sizing: border-box;
        display: inline-block;
        vertical-align: middle;
        margin-left: 20px;
        margin-right: 20px;
    }
}

@keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
