/* Builder Blocks - YouTube horizontal */
.youtube-horizontal {
    h4 {
        font-weight: 500;
    }
    .youtube-block {
        background: transparent !important;
        overflow: hidden;

        .embed-youtube {
            box-shadow: none;
        }
        .content {
            .description {
                display: block;
            }
            .bottom-bar {
                background: transparent !important;
                display: none;

                .icons {
                    margin-left: 0;

                    .share-icon {
                        text-align: center;

                        &:first-of-type {
                            margin-left: 0;
                        }
                    }
                    @media (max-width: 767px) {
                        margin-bottom: 20px;
                    }
                }
            }
        }
        &.boxed-yt-layout {
            padding: 0 !important;
            background: $gray-200 !important;
            margin-bottom: 25px !important;
            border-radius: 4px;
            @extend .shadow;

            .content {
                padding: 20px !important;
                text-align: center;

                @media (max-width: 767px) {
                    top: 0;
                    -webkit-transform: none;
                    -ms-transform: none;
                    transform: none;
                }
                h4 {
                    color: $primary !important;
                }
                .description p {
                    margin-bottom: 0 !important;
                }
            }
            .youtube-block {
                margin-top: 0 !important;
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
                box-shadow: none !important;

                .bottom-bar:not(.content .bottom-bar) {
                    background: transparent !important;
                }
                @media (max-width: 767px) {
                    border-top-right-radius: 0 !important;
                    border-bottom-left-radius: 4px !important;
                }
            }
        }
    }
    .wrapper {
        @extend .w-100;

        &.boxed-yt-layout {
            @extend .px-3;
        }
    }
    &.content-grey {
        .youtube-block {
            &.boxed-yt-layout {
                background: $white !important;
            }
        }
    }
}
