/* Builder Blocks - Horizontal rule divider */
.builder-hr-full {
    border-top: 1px solid $gray-200;
    border-radius: 4px;
    width: 100%;
    &.brand-1 {
        border-top: 1px solid $sl-green;
    }
    &.brand-2 {
        border-top: 1px solid $sl-blue;
    }
}
.builder-hr-half {
    border-top: 1px solid $gray-200;
    border-radius: 4px;
    width: 50%;
    &.brand-1 {
        border-top: 1px solid $sl-green;
    }
    &.brand-2 {
        border-top: 1px solid $sl-blue;
    }
}

@media (max-width: 575px) {
    .builder-hr-full {
        border-radius: 0 !important;
    }
}
