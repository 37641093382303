/* Team */
.team-custom-cards {
    .card-columns {
        -webkit-column-count: 3 !important;
        -moz-column-count: 3 !important;
        column-count: 3 !important;
        -webkit-column-gap: 30px;
        -moz-column-gap: 30px;
        column-gap: 30px;
        orphans: 1;
        widows: 1;
    }
    .card-cat {
        &:not(:first-child) {
            .cat {
                border-top: 4px solid $gray-200;
                padding-top: 20px;
            }
        }
    }
    .card-wrapper {
        .card {
            display: inline-block;
            width: 100%;
            margin-bottom: 2.25rem;
        }

        .card-title {
            transition: 0.3s all;

            .card-body {
                .name {
                    transition: 0.3s all;

                    &:hover {
                        color: $sl-green-dark !important;
                        transition: 0.3s all;
                    }
                }
                .jobtitle {
                    min-height: 55px;
                    max-height: 55px;
                    overflow: hidden;
                }
                .card-text {

                    &.fixed-height {
                        min-height: 165px;
                        max-height: 165px;
                        overflow: hidden;
                    }
                }
                @media (max-width: 767px) {
                    .jobtitle, .card-text {
                        min-height: auto !important;
                        max-height: auto !important;
                    }
                }
            }
            &:hover {
                transform: scale(1.01);
                transition: 0.3s all;
                @extend .shadow;
            }
        }
    }

    @media (max-width: 991px) {
        .card-columns {
            -webkit-column-count: 2 !important;
            -moz-column-count: 2 !important;
            column-count: 2 !important;
            -webkit-column-gap: 30px;
            -moz-column-gap: 30px;
            column-gap: 30px;
            orphans: 1;
            widows: 1;
        }
    }

    @media (max-width: 767px) {
        .card-columns {
            -webkit-column-count: 1 !important;
            -moz-column-count: 1 !important;
            column-count: 1 !important;
            -webkit-column-gap: 0;
            -moz-column-gap: 0;
            column-gap: 0;
            orphans: 1;
            widows: 1;
        }
    }
}
.team-profile-pic {
    width: 100%;
}
.team-linkedin-circle {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border-color: $sl-green-dark;
    border-style: none;
    background-color: $sl-green-dark;
    text-align: center;
    text-decoration: none !important;
    -webkit-box-shadow: 0 0 11px -3px rgba(0, 0, 0, 0.70);
    -moz-box-shadow: 0 0 11px -3px rgba(0, 0, 0, 0.70);
    box-shadow: 0 0 11px -3px rgba(0, 0, 0, 0.70);
    z-index: 10;
    transition: 0.25s all;

    span {
        display: none;
    }

    &:after {
        position: relative;
        top: 9px;
        left: 0;
        content: "\f0e1";
        font-family: "Font Awesome 5 Brands";
        font-size: 22px;
        font-weight: 900;
        color: $white;
    }

    &:hover {
        background-color: $sl-green-dark;
        border-color: $sl-green-dark;
        cursor: pointer;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        transition: 0.25s ease-out;

        &:after {
            top: 10px;
            content: "\f060";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
        }
    }
}
.team-profile-info {
    .team-linkedin-circle {
        right: 32px;
    }
}
.team-blockquote {
    position: relative;
    border: 0;
    color: $gray-700;
    padding: 1.5rem 3.4rem;
    margin-bottom: 10px;

    .lead {
        padding: 0 10px;
        font-size: 30px;
    }

    p {
        position: relative;
        margin-bottom: 0;
        z-index: 10;
    }
    &:before, &:after {
        position: absolute;
        opacity: 0.15;
        font-size: 2.7em;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
    }
    &:before {
        top: 0;
        left: 0;
        content: "\f10d";
    }
    &:after {
        bottom: 0;
        right: 0;
        content: "\f10e";
    }
}
