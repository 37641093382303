/* Builder Blocks - Trust USP's */
.trust-usps {
    .usp {
        background: $white;
        border-radius: 50%;
        border: 2px solid $white;
    }
    .owl-theme {
        .owl-stage {
            margin: 0 auto;
        }
        .owl-nav {
            height: 44px;
        }
    }
}
