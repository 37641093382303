/* Builder Blocks - In this section */
.in-this-section-block {
    margin: 15px 0 !important;

    .item {
        .box {
            border: none;
            background: $gray-200;
            transition: 0.3s all;

            .lead {
                color: $sl-green-dark;
                transition: 0.3s all;
                display: block;

                &:hover {
                    color: $sl-green-dark;
                    transition: 0.3s all;
                }

                @media (max-width: 1199px) {
                    min-height: 60px;
                    margin-bottom: 10px;
                }

                @media (max-width: 576px) {
                    min-height: auto;
                    margin-bottom: 15px;
                }
            }
            img {
                max-width: 100%;
            }
            &:hover {
                transform: scale(1.02);
                transition: 0.3s all;
            }
            p {
                @media (max-width: 767px) {
                    display: none;
                }
            }
        }
    }
    &.content-grey {
        .box {
            background: $white;
        }
    }
}
