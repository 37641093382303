/* Banners */
section#banner {
    position: relative;
    color: $white;
    background: $gray-200;
    height: 400px;
    -webkit-transition: background-image .8s ease-in-out;
    transition: background-image .8s ease-in-out;

    h1 {
        font-size: 2rem;
        margin-bottom: 1rem !important;
        margin-top: 1rem;
    }
    h1.home {
        font-size: 3rem;

        @media (max-width: 1199px) {
            font-size: 2.4rem;
        }
        @media (max-width: 991px) {
            font-size: 1.7rem;
        }
    }
    .banner-overlay {
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, .8));
        width: 85%;
        height: 100%;
    }
    blockquote {
        margin-top: 30px;
        padding: 0 50px 0 40px;

        &:before, &:after {
            position: absolute;
            font-family: "Font Awesome 5 Free";
            font-size: 60px;
            line-height: 40px;
            font-weight: 900;
            opacity: .3;
            display: inline;

            @media (max-width: 420px) {
                font-size: 50px;
                line-height: 30px;
            }
        }
        &:before {
            content: "\f10d";
            left: 20px;
            margin-top: -10px;
        }
        &:after {
            content: "\f10e";
            right: 35px;
        }
    }

    @media (min-width: 1921px) {
        max-width: 1650px;
        margin: 0 auto;
        box-shadow: 0 -100vw 0 100vw $gray-200;

        .banner-overlay {
            left: -358px;
            right: 0;
            width: 1042px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    @media (max-width: 991px) {
        .banner-overlay {
            background: linear-gradient(180deg, rgba(0,0,0,.6), rgba(0,0,0,.45) 73%, transparent);
            width: 100%;
        }
    }
    @media (max-width: 820px) and (max-height: 500px) {
        section#banner {
            background-image: none !important;
        }
    }

    /* Image banner */
    &.banner-image {
        background-size: cover;
        background-position: 60%;

        @media (max-width: 1024px) {
            height: auto;
        }
        @media (max-width: 991px) {
            background-position-x: 70%;
            background-position-y: top;
            background-repeat: no-repeat;
            background-color: $white;
        }

        .desktop-intro {
            padding-right: 180px;

            @media (max-width: 991px) {
                padding-right: 0;
            }
        }
        &.homepage {
            height: auto;

            .nocompletion {
                color: $sl-green;
                text-decoration: underline;
                transition: .25s all;

                &:hover {
                    color: $sl-green-dark;
                    cursor: pointer;
                    transition: .25s all;
                }
            }
            .modal {
                top: 180px;
                z-index: 1000000;
            }
            .colour-overlay {
                position: absolute;
                width: calc(50% + 100px);
                height: 100%;
                overflow: hidden;

                @media (max-width: 991px) {
                    width: 80%;
                }
                @media (max-width: 767px) {
                    width: 100%;
                }
                @media (max-width: 575px) {
                    background: $sl-blue-dark;

                    img {
                        display: none;
                    }
                }
                img {
                    position: absolute;
                    top: 50%;
                    left: -10px;
                    width: 100%;
                    -webkit-transform: translateY(-50%);
                    -ms-transform: translate(-50%,-50%);
                    transform: translateY(-50%);

                    @media (max-width: 1199px) {
                        width: 100%;
                    }
                    @media (max-width: 991px) {
                        width: calc(100% + 20px);
                    }
                    @media (max-width: 767px) {
                        width: calc(100% + 70px);
                    }
                }
            }
            .left-content {
                padding: 35px 55px 45px 16px;

                @media (max-width: 991px) {
                    padding-right: 260px;
                }
                @media (max-width: 768px) {
                    padding-right: 300px;
                }
                @media (max-width: 767px) {
                    padding-right: 170px;
                }
                @media (max-width: 700px) {
                    padding-right: 130px;
                }
                @media (max-width: 575px) {
                    padding: 20px 100px 20px 16px;
                }
                @media (max-width: 480px) {
                    padding: 20px 16px 20px 16px;
                }
                ul.homepage-usps {
                    list-style: none;

                    li {
                        padding-left: 25px;

                        &:before {
                            content: "\f00c";
                            font-family: "Font Awesome 5 Free";
                            font-weight: 900;
                            font-size: 20px;
                            color: $primary;
                            margin: 0 6px 0 -25px;
                        }
                    }
                }
                a {
                    text-decoration: underline !important;
                }
            }
            .tp-wrapper-inline {
                position: relative;
                bottom: 0;

                .widget-info {
                    /*display: block;*/
                }
            }
            @media (min-width: 992px) {
                .call-back-form-header {
                    position: relative;
                    top: -18px;
                }
            }
            @media (min-width: 1025px) {
                min-height: 495px;

                .call-back-form-header {
                    position: relative;
                    top: 10px;
                }
            }
            @media (min-width: 1200px) {
                min-height: 450px
            }
        }
    }

    /* Basic banners (with and without icon) */
    &.banner-default {
        background: $sl-green-dark;

        &.basic {
            height: auto;

            .tp-wrapper-inline {
                position: relative;
                bottom: 0;
            }
        }
        .desktop-intro {
            padding-right: 180px;

            @media (max-width: 991px) {
                padding-right: 0;
            }
        }
        .icon {
            position: absolute;
            top: calc(50% - -6px);
            right: -100px;
            width: 320px;
            opacity: 0.15;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

            @media (min-width: 1921px) {
                position: relative;
                top: 100px;
                right: -210px;
            }
            @media (max-width: 1920px) {
                right: -50px;
            }
            @media (max-width: 1024px) {
                width: 220px;
            }
            @media (max-width: 920px) {
                right: -100px;
            }
            @media (max-width: 820px) {
                display: none;
            }
        }

        .ppc-tp-wrapper {
            .tp-wrapper-inline {
                position: relative;
                bottom: 0;
                padding-bottom: 25px;
            }
        }

        @media (max-width: 1024px) {
            height: auto;
        }
    }

    /* Location banner */
    &.banner-surveyor {
        height: 400px;

        @media (max-width: 991px) {
            height: auto;

            .banner-4k {
                .call-back-form-header {
                    top: 40px;
                    margin-top: -40px;
                }
            }
        }
    }
}
