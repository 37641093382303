/* Home sections */
.how-it-works {
    .icon,
    .content {
        float: left;
    }
    .icon {
        width: 30%;
    }
    .content {
        width: 70%;
        line-height: 1.3;
    }
    @media (max-width: 767px) {
        .icon {
            width: 18%;
        }
        .content {
            width: 82%;
        }
    }
}
.hero-section {
    position: relative;
    padding: 6rem 0;
    overflow: hidden;

    &.left,
    &.right {
        .content {
            padding-left: 40px;
            z-index: 10;

            h3 {
                position: relative;
                font-size: 50px;
                line-height: 1.1;

                &:before {
                    position: absolute;
                    left: -20px;
                    content: "";
                    width: 6px;
                    height: 100%;
                    border-radius: 4px;
                }
            }

            h2 {
                position: relative;
                font-size: 2.5rem;
                /*line-height: 1.1;*/

                &:before {
                    position: absolute;
                    left: -20px;
                    content: "";
                    width: 6px;
                    height: 100%;
                    border-radius: 4px;
                }
            }
        }
        .image {
            position: absolute;
            top: 0;
            height: 100%;
            background-size: cover;
            background-position-y: 60%;
        }
        @media (max-width: 991px) {
            .content {
                h3 {
                    font-size: 40px;
                }
            }
        }
        @media (max-width: 767px) {
            padding: 3rem 0;

            .content {
                padding-left: 15px;

                h3 {
                    font-size: 1.25rem;
                    line-height: 1.3;

                    &:before {
                        display: none;
                    }
                }
            }
            .image {
                display: none;
            }
        }
    }
    &.left {
        .content {
            h3 {
                &:before {
                    background: $primary;
                }
            }
            h2 {
                &:before {
                    background: $primary;
                }
            }
        }
        .image {
            right: 85px;
            width: 40%;
            background-image: url("https://cdn.surveyorlocal.co.uk/media/assets/images/homepage/hero-section-1.png");

            @media (min-width: 1921px) {
                left: 50%;
                width: 25%;
            }
            @media (max-width: 1199px) {
                width: 52%;
                right: -50px;
            }
            @media (max-width: 991px) {
                width: 62%;
                right: -100px;
            }
        }
    }
    &.right {
        .content {
            h3 {
                &:before {
                    background: $sl-blue-dark;
                }
            }
            h2 {
                &:before {
                    background: $sl-blue-dark;
                }
            }
        }
        .image {
            left: 40px;
            width: 50%;
            background-image: url("https://cdn.surveyorlocal.co.uk/media/assets/images/homepage/hero-section-2.png");

            @media (min-width: 1921px) {
                left: calc(50% - 760px);
                width: 30%;
            }
            @media (max-width: 1199px) {
                width: 60%;
                left: -50px;
            }
            @media (max-width: 991px) {
                width: 70%;
                left: -100px;
            }
            @media (max-width: 767px) {
                left: auto;
            }
        }
    }
}
.news-home {
    .card {
        margin-bottom: 20px;
        min-height: calc(100% - 20px);
        transition: all .3s;

        .card-img-top {
            height: 170px;
            overflow: hidden;

            img {
                width: 100%;
            }
            @media (max-width: 1199px) {
                height: 140px;
            }
            @media (max-width: 991px) {
                height: 130px;
            }
            @media (max-width: 767px) {
                height: auto;
            }
        }
        .card-body {
            .content {
                h4 {
                    /*font-size: 18px;
                    font-weight: 400 !important;*/
                }
            }
        }
        &:hover {
            transform: scale(1.035);
            transition: all .3s;
        }
    }
}
.find-surveyors {
    position: relative;
    padding: 5rem 0 9rem 0;
    overflow: hidden;

    .content {
        z-index: 10;

        form {
            label {
                color: $sl-blue-dark !important;
            }
            .input-area {
                position: absolute;
                width: calc(100% - 30px);
                border-radius: 0.25rem;
                overflow: hidden;
                -webkit-box-shadow: 0 0 18px -5px rgba(0, 0, 0, .5);
                -moz-box-shadow: 0 0 18px -5px rgba(0, 0, 0, .5);
                box-shadow: 0 0 18px -5px rgba(0, 0, 0, .5);

                input,
                button {
                    float: left;
                    margin: 0 !important;
                }
                input {
                    width: 65% !important;
                    border-color: $white;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
                button {
                    width: 35% !important;
                    color: $white;
                    background-color: $sl-yellow-dark;
                    border-color: $sl-yellow-dark;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;

                    &:hover {
                        color: $white;
                        background-color: darken($sl-yellow-dark, 12%);
                        border-color: darken($sl-yellow-dark, 12%);
                    }
                    &:active, &:focus {
                        color: $white;
                        background-color: darken($sl-yellow-dark, 12%);
                        border-color: darken($sl-yellow-dark, 12%);
                        box-shadow: none;
                    }
                }
                @media (max-width: 1199px) {
                    input {
                        width: 55% !important;
                    }
                    button {
                        width: 45% !important;
                    }
                }
                @media (max-width: 991px) {
                    input {
                        width: 52% !important;
                    }
                    button {
                        width: 48% !important;
                    }
                }
                @media (max-width: 575px) {
                    input,
                    button {
                        width: 100% !important;
                    }
                    input {
                        border-top-right-radius: 0.25rem;
                        border-bottom-left-radius: 0;
                    }
                    button {
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                    }
                }
            }
        }
    }
    .image {
        position: absolute;
        top: 0;
        right: 45px;
        height: 100%;
        width: 50%;
        background-size: cover;
        background-position-y: 50%;
        background-image: url("https://cdn.surveyorlocal.co.uk/media/assets/images/homepage/surveyors-section-art.png");
    }
    @media (max-width: 1199px) {
        .image {
            width: 70%;
            right: -140px;
        }
    }
    @media (max-width: 991px) {
        .image {
            width: 80%;
            right: -220px;
        }
    }
    @media (max-width: 767px) {
        padding: 3rem 0 6rem 0;

        .image {
            display: none;
        }
    }
    @media (max-width: 575px) {
        padding: 3rem 0 8rem 0;
    }
}
.more-information {
    .ctas {
        .card.cta-1,
        .card.cta-2 {
            overflow: hidden;

            .image {
                height: auto;
                overflow: hidden;

                .image-inner {
                    background-size: cover;
                    background-position-x: 50%;
                    background-position-y: 50%;
                    transition: .25s linear;
                }
                @media (max-width: 575px) {
                    height: 300px;
                }
                @media (max-width: 575px) {
                    height: 250px;
                }
                @media (max-width: 320px) {
                    height: 160px;
                }
            }
            &:hover {
                .image {
                    .image-inner {
                        transform: scale(1.2);
                        transition: .4s ease-in-out;
                    }
                }
            }
            @media (max-width: 575px) {
                .image {
                    .image-inner {
                        transform: scale(1) !important;
                    }
                }
                .content {
                    .btn {
                        width: 100%;
                    }
                }
            }
        }
        .card.cta-1 {
            background: $sl-green;

            .content {
                .btn {
                    color: $sl-green;
                    background-color: $white;
                    border-color: $white;

                    &:hover {
                        color: $sl-green-dark;
                        background-color: $gray-200;
                        border-color: $gray-200;
                    }
                }
            }
        }
        .card.cta-2 {
            background: $sl-blue-dark;

            .content {
                .btn {
                    color: $sl-blue-dark;
                    background-color: $white;
                    border-color: $white;

                    &:hover {
                        color: $sl-blue-dark;
                        background-color: $gray-200;
                        border-color: $gray-200;
                    }
                }
            }
        }
    }
    .tp {
        position: relative;

        .overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 15%;
            background: rgb(0,0,0);
            background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 30%, rgba(255,255,255,0) 100%);
            z-index: 10;
        }
        @media (max-width: 1199px) {
            .trustpilot-widget {
                iframe {
                    height: 460px !important;
                }
            }
        }
        @media (max-width: 991px) {
            .trustpilot-widget {
                iframe {
                    height: 650px !important;
                }
            }
        }
        @media (max-width: 767px) {
            .overlay {
                background: $white !important;
            }
            .trustpilot-widget {
                margin-top: 25px;

                iframe {
                    height: 95px !important;
                }
            }
        }

    }
}
