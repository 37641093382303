/* Builder Blocks - FAQ block */
.faq-block {
    .owl-theme {
        .owl-item {
            p {
                padding-left: 1.5rem !important;
                padding-right: 1.5rem !important;
            }
            ul, ol {
                margin-left: 20px !important;
            }
        }
        .owl-dots {
            position: absolute;
            right: 0;
            bottom: -46px;
        }
        .owl-nav {
            font-size: 24px;
            height: 34px;

            .owl-prev {
                left: 0;
                margin: 0;
            }
            .owl-next {
                right: 0;
                margin: 0;
            }
        }
    }
    .btn {
        position: relative;
        left: 24px;
    }
    &.grey-bg, &.white-bg {
        .owl-theme {
            .owl-nav {
                .fa {
                    color: $sl-green;
                }
            }
        }
    }
}
