/* Builder Blocks - Block link */
.block-link {
    &.brand-1-bg {
        border: 2px solid $sl-green;
    }
    &.brand-2-bg {
        border: 2px solid $gray-700;
    }
    &.grey-bg {
        border: 2px solid $gray-200;
    }
    &.white-bg {
        border: 2px solid $white;
    }
    .image {
        width: 100%;
    }
    .content {
        h5 {
            @media (max-width: 1199px) {
                font-size: 1rem;
                line-height: 1.6;
            }
        }
    }
    &.with-description {
        a {
            position: relative;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%,-50%);
            -ms-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
        }
    }
}

@media (max-width: 767px) {
    .block-link {
        padding-bottom: 15px !important;

        .image {
            border-radius: .25rem .25rem 0 0 !important;
        }
    }
}

@media (max-width: 575px) {
    .block-link {
        .image {
            border-radius: 0 !important;
        }
    }
}
