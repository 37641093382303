/* Modals */
.modal {
    .modal-body {
        p {
            color: $gray-700;
        }
    }
    .modal-footer {
        .btn-close {
            width: inherit !important;
            .fas {
                color: $white;
                margin-left: 8px;
            }
        }
    }
}

@media (min-width: 768px) {
    .modal {
        .modal-dialog {
            max-width: 50%;
        }
    }
}
