/* Header */
header {
    background-color: $white;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 111000;

    #top-bar {
        height: 48px;
        padding: 12px 0;
        background: $gray-200;

        nav.top-bar-menu {
            text-align: right;
            width: 100%;

            ul {
                li {
                    display: inline !important;
                    padding: 0 15px;
                    border-left: 2px solid $sl-green;

                    &:first-child {
                        border-left: none;
                        padding-left: 0;
                    }
                    &.current a {
                        color: $sl-green;

                        &:hover {
                            color: $sl-green-dark;
                        }
                    }

                    a {
                        color: $gray-500;
                        text-decoration: none;

                        &:hover {
                            color: $gray-700;
                        }
                    }
                }
            }
        }
        @media (max-width: 1024px) {
            display: none;
        }
    }
    #main-bar {
        height: 100px;
        background: $white;
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -o-transition: all .3s ease;
        -ms-transition: all .3s ease;
        transition: all .3s ease;
    }
    .main-bar-content {
        .logo-area {
            width: 50%;

            .logo {
                position: relative;
                top: 12px;
                height: 60px;
                width: 180px;
                -webkit-transition: all .3s ease;
                -moz-transition: all .3s ease;
                -o-transition: all .3s ease;
                -ms-transition: all .3s ease;
                transition: all .3s ease;

                &:hover {
                    opacity: .6;
                    -webkit-transition: all .3s ease;
                    -moz-transition: all .3s ease;
                    -o-transition: all .3s ease;
                    -ms-transition: all .3s ease;
                    transition: all .3s ease;
                }
            }
        }

        .details-right {
            position: relative;
            width: 50%;

            .ctas-btns {
                position: relative;
                right: 190px;
                top: 8px;
            }
            .mobile-menu-container {
                font-size: 8px;
                text-align: center;
                margin-bottom: 0;
                margin-top: -1px;
                color: $sl-blue;

                button.burger {
                    position: relative;
                    top: 7px;
                    border: none;
                    background: none;
                    outline: none;
                    cursor: pointer;
                    width: 100%;
                    padding-bottom: 6px !important;
                    transition: all .25s ease-in-out;
                    z-index: 20000;

                    span {
                        width: 35px;
                        height: 5px;
                        display: block;
                        margin: 7px 0;
                        background-color: $sl-blue;
                        border-radius: 2px;
                    }
                }
                .menu-icon {
                    span {
                        transition: all 0.35s ease-in-out;
                    }
                    &.menu-open {
                        span {
                            background-color: $sl-green;

                            &:nth-child(2) {
                                opacity: 0;
                                transform: translateX(-100%);
                            }
                            &:nth-child(1) {
                                transform: translateY(12px) rotateZ(135deg);
                            }
                            &:nth-child(3) {
                                transform: translateY(-12px) rotateZ(-135deg);
                            }
                        }
                    }
                }
            }

            .opening {
                position: relative;
                top: 10px;
                float: right;
            }
            .bar-links {
                position: relative;
                top: -2px;
                float: right;
                clear: both;

                a {
                    display: inline-block;
                    padding-top: 11px;
                    margin-left: 10px;
                    text-align: center;
                    text-decoration: none;
                    max-width: 50px;
                    font-size: 8px;
                    line-height: 1;
                    margin-bottom: 0;

                    .phone {
                        white-space: nowrap;
                        float: right;
                        clear: both;
                        font-weight: 700;
                        font-size: 32px;
                        line-height: 40px;
                        color: $sl-green;
                        text-decoration: none;
                        -webkit-transition: all .3s ease;
                        -moz-transition: all .3s ease;
                        -o-transition: all .3s ease;
                        -ms-transition: all .3s ease;
                        transition: all .3s ease;

                        &:hover {
                            color: $sl-green-dark;
                            -webkit-transition: all .3s ease;
                            -moz-transition: all .3s ease;
                            -o-transition: all .3s ease;
                            -ms-transition: all .3s ease;
                            transition: all .3s ease;
                        }
                        &:active {
                            color: $sl-green-dark;
                        }
                    }
                    .phone-icon {
                        height: 40px;
                        width: 40px;
                        color: $sl-green;
                        /*border: 2px solid $sl-green;*/
                        border-radius: 100%;
                        font-size: 18px;
                        padding: 9px;
                        transition: .25s ease-in-out;

                        svg {
                            display: inherit;
                        }

                        &:hover {
                            color: $sl-green-dark;
                            border: 2px solid $sl-green-dark;
                            transition: .25s ease-in-out;
                        }
                    }
                    img {
                        width: 31px;
                        padding-bottom: 7px;
                    }
                    &:nth-child(1) {
                        color: $danger;
                    }
                    &:nth-child(2) {
                        color: $sl-blue;
                    }
                    &.search-mobile {
                        color: $sl-blue;
                    }
                }
                span {
                    display: inline-block;
                }
            }
        }
    }
}
main {
    margin-top: 120px;
}

nav.menu {
    ul.navigation {
        label {
            margin-bottom: 0;
        }
    }
}
.call-back-sticky-shadow {
    -webkit-box-shadow: 0 0 18px -5px rgba(0,0,0,.5);
    -moz-box-shadow: 0 0 18px -5px rgba(0,0,0,.5);
    box-shadow: 0 0 18px -5px rgba(0,0,0,.5);
}
.nah-sticky-scroll {
    position: fixed !important;
    top: -300px;
    width: 100%;
    z-index: 110101;
    /*opacity: 0;*/
    background: $white;
    transition: 0.3s all;
    box-shadow: 0 2px 3px rgba(0,0,0,0.15);
}
.nah-show {
    top: 0;
    opacity: 1;
}

/* Responsive */
@media (min-width: 1025px) {
    header {
        #main-bar {
            .mobile-menu-container {
                display: none;
            }
        }
    }
}
@media (max-width: 1024px) {
    header {
        #main-bar {
            height: 100px;

            .main-bar-content {
                .details-right {
                    .opening {
                        position: relative;
                        top: 15px;
                        right: 52px;
                        font-size: 14px;
                    }
                    .bar-links {
                        .phone {
                            font-size: 28px !important;
                        }
                    }
                    .ctas-btns {
                        right: 160px;
                    }
                }
            }
        }
        #main-nav {
            display: none;
        }
    }
    main {
        margin-top: 90px;
    }
}
@media (max-width: 991px) {
    header {
        #main-bar {
            height: 80px;

            .main-bar-content {
                .logo-area {
                    .logo {
                        top: 0;
                        max-width: 260px;
                        height: auto;
                    }
                }
                .details-right {
                    top: -10px;
                }
            }
        }
    }
    main {
        margin-top: 50px;
    }
}
@media (max-width: 767px) {
    header {
        #main-bar {
            height: 130px;

            .main-bar-content {
                .logo-area {
                    position: absolute;
                    z-index: 100;

                    .logo {
                        max-width: 220px;
                    }
                }
                .details-right {
                    width: 100%;

                    .ctas-btns {
                        right: 0;
                        top: 5px;
                        clear: both;

                        .btn {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    main {
        margin-top: 105px;
    }
}
@media (max-width: 575px) {
    header {
        #main-bar {
            height: 140px;

            .main-bar-content {
                p {
                    font-weight: 700;
                    margin-bottom: 0;
                }
                .logo-area {
                    .logo {
                        top: 10px;
                    }
                }
                .details-right {
                    top: -10px;

                    .opening {
                        top: 10px;
                        right: 0;
                    }
                    .bar-links {
                        position: relative;
                        top: 12px;

                        .phone-icon {
                            position: relative;
                            top: -5px;
                        }
                    }
                    .ctas-btns {
                        top: 20px !important;
                    }
                    .bar-links {
                        display: flex;
                    }
                }
            }
        }
    }
}
@media (max-width: 360px) {
    header {
        #main-bar {
            .main-bar-content {
                .logo-area {
                    .logo {
                        max-width: 190px;
                    }
                }

            }
        }
    }
}
@media (max-width: 319px) {
    header {
        #main-bar {
            .main-bar-content {
                .logo-area {
                    .logo {
                        max-width: 100%;
                        top: 20px;
                    }
                }

            }
        }
    }
}
