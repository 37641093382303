/* Layout */
body {
    &.no-scroll {
        overflow: hidden;
    }
    @media (min-width: 1600px) {
        .container {
            max-width: 1440px !important;
        }
    }
}
main {
    .page-content {
        font-size: 18px;
    }
}
.full-screen-overlay-search,
.full-screen-overlay-mobile {
    position: fixed;
    visibility: hidden;
    background: $black;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 110000;
    transition: opacity .3s ease-in;
    &.on {
        visibility: visible !important;
        opacity: 0.8;
    }
}
.full-screen-overlay-search-mobile {
    position: fixed;
    top: 0;
    visibility: hidden;
    background: $white;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 111000;
    transition: opacity .3s ease-in;
    &.on {
        visibility: visible !important;
        opacity: 0.8;
    }
}
.full-screen-overlay-mobile, .full-screen-overlay-search-mobile, .search-form-mobile {
    @media (min-width: 1025px) {
        display: none;
    }
}
.full-screen-overlay-search, .search-form {
    @media (max-width: 1025px) {
        display: none;
    }
}

/* Sections */
section.content-white {
    background-color: $white;

    h1, h2, h3, h4, h5, h6, p a {
        color: $sl-green;
    }
    p a {
        &:hover {
            color: $sl-green-dark;
        }
    }
}
section.content-grey {
    background-color: $gray-200;

    h1, h2, h3, h4, h5, h6, p a {
        color: $sl-green;
    }
    p a {
        &:hover {
            color: $sl-green-dark;
        }
    }
    &.open-accordion {
        .accordion-hr {
            display: none;
        }
    }
    &.faded {
        background: linear-gradient(180deg, rgba(233,236,239,1) 25%, rgba(233,236,239,0) 100%);
    }
}
section.content-green {
    background-color: $sl-green;
}
section.content-blue {
    background-color: $sl-blue;
}
section.content-yellow {
    background-color: $sl-yellow;
}
section.content-grey-dark {
    background-color: $gray-700;
}
section.content-white,
section.content-grey {
    h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
        &:hover {
            color: $sl-green-dark;
        }
    }

    ul {
        list-style-type: none;
        margin-left: 20px;
        padding-left: 0;
        padding-right: 15px;

        li {
            position: relative;
            left: 1.4em;
            text-indent: -1.3em;
            width: 95%;
            margin-bottom: 8px;

            &:before {
                content: '\f054';
                font-family: "Font Awesome 5 Free";
                font-size: inherit;
                font-weight: 900;
                color: $sl-green;
                position: relative;
                left: 0;
                margin-right: 12px;
            }
            &:last-child {
                margin-bottom: 16px;
            }
        }
        ul {
            list-style: none;
            margin-left: 25px;
            margin-top: 5px;

            li {
                text-indent: -0.8em;
                left: 0.8em;

                &:before {
                    content: '\f105';
                    font-family: "Font Awesome 5 Free";
                    font-size: inherit;
                    color: $sl-green-dark;
                    margin-right: 8px;
                }
                &:last-child {
                    margin-bottom: 12px;
                }
            }
        }

        &.primary {
            li {
                &:before {
                    color: $sl-green;
                }
                a {
                    color: $sl-green !important;
                }
            }
            ul {
                li {
                    &:before {
                        color: $sl-green-dark;
                    }
                }
            }
        }
        &.secondary {
            li {
                &:before {
                    color: $sl-blue;
                }
                a {
                    color: $sl-blue !important;
                }
            }
            ul {
                li {
                    &:before {
                        color: $sl-blue-dark;
                    }
                }
            }
        }
        &.info {
            li {
                &:before {
                    color: $sl-yellow;
                }
                a {
                    color: $sl-yellow !important;
                }
            }
            ul {
                li {
                    &:before {
                        color: $sl-yellow-dark;
                    }
                }
            }
        }
    }
    ol {
        list-style: none;
        margin-left: 20px;
        padding-left: 0;
        padding-right: 15px;
        counter-reset: list-counter;

        li {
            position: relative;
            left: 1.4em;
            text-indent: -1.3em;
            width: 95%;
            counter-increment: list-counter;
            margin-bottom: 8px;

            &:before {
                content: counter(list-counter) " ";
                color: $sl-green;
                font-weight: 700;
                margin-right: 6px;
            }
            &:last-child {
                margin-bottom: 32px;
            }
        }
        ol {
            list-style: none;
            margin-left: 0;
            counter-reset: list-counter;
            margin-top: 5px;

            li {
                text-indent: -0.8em;
                left: 0.8em;
                counter-increment: list-counter;

                &:before {
                    content: counter(list-counter) "  ";
                    font-weight: 700;
                    font-size: inherit;
                    color: $sl-green-dark;
                    margin-right: 4px;
                }
                &:last-child {
                    margin-bottom: 12px;
                }
            }
        }

        &.primary {
            li {
                &:before {
                    color: $sl-green;
                }
            }
            ol {
                li {
                    &:before {
                        color: $sl-green-dark;
                    }
                }
            }
        }
        &.secondary {
            li {
                &:before {
                    color: $sl-blue;
                }
            }
            ol {
                li {
                    &:before {
                        color: $sl-blue-dark;
                    }
                }
            }
        }
        &.info {
            li {
                &:before {
                    color: $sl-yellow;
                }
            }
            ol {
                li {
                    &:before {
                        color: $sl-yellow-dark;
                    }
                }
            }
        }
    }
}
section.content-green,
section.content-blue,
section.content-yellow,
section.content-grey-dark {
    p, h1, h2, h3, h4, h5, h6, a {
        color: $white;
    }
    ul {
        list-style-type: none;
        margin-left: 15px;
        padding-left: 0;

        li {
            position: relative;
            left: 1.4em;
            text-indent: -1.3em;
            width: 95%;
            color: $white;

            &:before {
                content: '\f054';
                font-family: "Font Awesome 5 Free";
                font-size: inherit;
                font-weight: 900;
                color: $white;
                position: relative;
                left: 0;
                margin-right: 12px;
            }
            a {
                color: inherit !important;
            }
        }
        ul {
            list-style: none;
            padding-left: 0;
            margin-left: 0;

            li {
                &:before {
                    content: '\f105';
                    font-family: "Font Awesome 5 Free";
                    font-size: inherit;
                    color: $white;
                    margin-right: 6px;
                }
            }
        }

        &.primary,
        &.secondary,
        &.info {
            li {
                &:before {
                    color: $white;
                }
            }
            ul {
                li {
                    &:before {
                        color: $white;
                    }
                }
            }
        }
    }
    ol {
        list-style: none;
        margin-left: 15px;
        padding-left: 0;
        counter-reset: list-counter;

        li {
            position: relative;
            left: 1.4em;
            text-indent: -1.3em;
            width: 95%;
            color: $white;
            counter-increment: list-counter;

            &:before {
                content: counter(list-counter) ". ";
                color: $white;
                font-weight: 700;
            }
            a {
                color: inherit !important;
                text-decoration: underline;
            }
        }

        ol {
            list-style: none;
            margin-left: 0;
            counter-reset: list-counter;

            li {
                counter-increment: list-counter;

                &:before {
                    text-indent: -0.8em;
                    left: 0.8em;
                    font-weight: 700;
                    content: counter(list-counter) ". ";
                }
            }
        }

        &.primary,
        &.secondary,
        &.info {
            li {
                &:before {
                    color: $white;
                }
            }
            ol {
                li {
                    &:before {
                        color: $white;
                    }
                }
            }
        }
    }
}
section.content-yellow {
    p, h1, h2, h3, h4, h5, h6, a {
        color: $sl-blue-dark;
    }
    h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, p a {
        color: $white;
        &:hover {
            color: $sl-blue-dark;
        }
    }
}

/* Related content section */
section#footer-related-content {
    .item {
        .box {
            border: none;
            background: $gray-200;
            transition: 0.3s all;

            img {
                max-width: 100%;
            }

            .lead {
                color: $sl-green-dark;
                transition: 0.3s all;

                &:hover {
                    color: $sl-green-dark;
                    transition: 0.3s all;
                }
            }
            &:hover {
                transform: scale(1.02);
                transition: 0.3s all;
            }
        }
    }
}

/* Global items */
hr {
    border-top: 1px solid $gray-300;
    border-radius: 2px;
}
.shadow {
    -webkit-box-shadow: 0 0 18px -5px rgba(0,0,0,0.5);
    -moz-box-shadow: 0 0 18px -5px rgba(0,0,0,0.5);
    box-shadow: 0 0 18px -5px rgba(0,0,0,0.5);
}
.no-shadow {
    box-shadow: none !important;
}

/* Global builder accordion */
.builder-accordion {
    .collapse, .collapsing {
        .page-section {
            margin: 0 -15px !important;
        }
    }
    .builder-accordion-title-wrapper {
        display: block;
        width: 100%;

        .builder-accordion-title {
            color: $sl-green;
            padding-right: 30px;
            transition: .3s all;

            &:after {
                position: absolute;
                right: 15px;
                content: "\f077";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                font-size: 22px;
                margin-top: 8px;
                transition: .2s all;
            }
        }
        &.collapsed {
            .builder-accordion-title {
                &:after {
                    content: "\f077";
                    transform: rotate(180deg);
                    transition: .2s all;
                }
            }
        }
        &:hover {
            cursor: pointer;

            .builder-accordion-title {
                color: $sl-green-dark;
                transition: .3s all;
            }
        }
    }
    hr {
        margin-bottom: 10px;
    }
    @media (min-width: 769px) {
        .builder-accordion-title-wrapper {
            &:hover {
                cursor: initial;
            }
            .builder-accordion-title {
                &:hover {
                    color: $sl-green-dark;
                }
                &:after {
                    display: none;
                }
            }
        }
        .collapse {
            display: block !important;
        }
    }
    @media (max-width: 480px) {
        .builder-accordion-title-wrapper {
            .builder-accordion-title {
                margin-bottom: 0 !important;
                &:after {
                    margin-top: 0;
                }
            }
        }
        hr {
            margin-bottom: 0;
        }
    }
}

/* Tooltip */
.tooltip-inner {
    max-width: 235px !important;
    padding: 6px 10px;
    background: $sl-green-dark;
    color: $white;
    @media (max-width: 420px) {
        display: none !important;
    }
}
.bs-tooltip-auto[x-placement^=top] .arrow::before,.bs-tooltip-top .arrow::before {
    border-top-color: $sl-green-dark !important;
    @media (max-width: 420px) {
        display: none !important;
    }
}
.tooltip.show {
    opacity: 1;
}
