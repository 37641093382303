/* Form control defaults */
.parsley-errors-list {
    display: none;
}
.form-control {
    font-weight: 400;

    &::placeholder {
        color: darken($gray-200, 20%) !important;
        font-size: 16px !important;
    }
}
select.form-control {
    color: $gray-400;

    &:invalid {
        color: darken($gray-200, 20%) !important;
    }
}
input.form-control,
textarea.form-control,
select.form-control {
    &:focus {
        &::placeholder {
            color: transparent !important;
        }
    }
}

/* Form validation styles */
.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: $danger !important;
    padding-right: calc(1.5em + .75rem);
    background-image: url("//cdn.surveyorlocal.co.uk/media/assets/images/theme/invalid.svg") !important;
    background-repeat: no-repeat !important;
    background-position: calc(100% - 8px) !important;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem) !important;
}
.form-control.is-valid,
.was-validated .form-control:valid {
    border-color: $sl-green !important;
    padding-right: calc(1.5em + .75rem);
    background-image: url("//cdn.surveyorlocal.co.uk/media/assets/images/theme/valid.svg") !important;
    background-repeat: no-repeat !important;
    background-position: calc(100% - 8px) !important;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem) !important;
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
    color: $sl-green-dark;
}
.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
    color: $danger;
}
.form-check {
    padding-left: 0;
}
input.form-check-input {
    margin-left: 0;
}
label.form-check-label {
    margin-left: 1.25rem;
}

/* Desktop search form */
.search-form {
    position: absolute;
    top: 0;
    height: 42px;
    width: calc(100% - 30px);
    visibility: hidden;
    background: $gray-200;
    opacity: 0;
    transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;

    form {
        height: 100%;
    }

    .search-field {
        height: 100%;
        width: 80%;

        input {
            padding-bottom: 2px;
        }
        &:before {
            position: absolute;
            top: 10px;
            left: 10px;
            content: "\f054";
            font-family: "Font Awesome 5 Free";
            color: $gray-700;
            font-weight: 900;
        }
    }

    .form-control {
        padding: 0 0 0 30px;
        height: 100%;
        background: $gray-200;
        border: none;
        color: $gray-700;
        caret-color: $gray-700;

        &::placeholder {
            color: $gray-400;
            position: relative;
            left: 5px;
        }
    }
    .btn-search, .close-btn {
        height: 100%;
        padding: 6px 15px;
    }
    .close-btn {
        color: $white;
        background: $gray-700;

        &:hover {
            background: $gray-800;
        }
    }
}
.search-open {
    visibility: visible;
    opacity: 1;
    transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;
}

/* Mobile search form */
.search-form-mobile {
    position: absolute;
    top: 0;
    height: 42px;
    width: 100%;
    visibility: hidden;
    background: $gray-200;
    opacity: 0;
    z-index: 113000;
    -webkit-box-shadow: 0 0 18px -5px rgba(0,0,0,.5);
    -moz-box-shadow: 0 0 18px -5px rgba(0,0,0,.5);
    box-shadow: 0 0 18px -5px rgba(0,0,0,.5);
    transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;

    form {
        height: 100%;
    }

    .search-field {
        height: 100%;
        width: 100%;

        input {
            width: calc(100% - 92px);
            padding-left: 30px;
        }
        &:before {
            position: absolute;
            top: 10px;
            left: 15px;
            content: "\f054";
            font-family: "Font Awesome 5 Free";
            color: $sl-green;
            font-weight: 900;
        }
    }

    .form-control {
        padding: 0;
        height: 100%;
        background: $gray-200;
        border: none;
        color: $gray-900;
        caret-color: $gray-700;

        &::placeholder {
            color: #8c8c8c;
        }
    }
    .btn {
        height: 100%;
        padding: 6px 15px;
        width: 46px;
    }
}
.search-mobile-open {
    visibility: visible;
    opacity: 1;
    transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;
}

/* Page form */
.page-form {
    .form-control {
        background: $gray-200;
        border: 1px solid $gray-200;
    }
    .fas {
        color: $sl-green;
    }
    @media (max-width: 767px) {
        .submit {
            width: 100%;
        }
    }
}

/* FAQs search form */
.input-faq {
    background: $gray-200;
    width: 50%;

    &:focus {
        background: $gray-200;
    }
}
.btn-faq {
    .fas {
        color: $white;
    }
}
@media (max-width: 767px) {
    .input-faq {
        width: 100%;
    }
    .btn-faq {
        width: 70%;
    }
    .clear-faq {
        width: calc(30% - 13px);
    }
}
@media (max-width: 375px) {
    .input-faq {
        width: 100%;
    }
    .btn-faq {
        width: 100%;
    }
    .clear-faq {
        width: 100%;
    }
}
