.exity-container {
    position: fixed;
    top: 0;
    z-index: 999999;
    display: none;
    background: rgba(0, 0, 0, .6);
    width: 100vw;
    height: 100vh;
    .exity-content {
        height: 380px;
        width: 640px;
        margin: 0 auto;
        background: #fff;
        top: 25%;
        position: relative;
        border-radius: 6px;
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .45);
        .exity-close {
            position: absolute;
            right: -10px;
            top: -10px;
            background: $sl-blue-dark;
            color: $white;
            font-size: 24px;
            border-radius: 100%;
            height: 38px;
            width: 38px;
            text-align: center;
            border: 2px solid $white;
            cursor: pointer;

            &:hover {
                background: $sl-blue;
            }

            i {
                color: $white;
            }
        }
        h2 {
            color: $primary;
            margin: 0 auto;
            text-align: center;
            padding: 50px 20px 15px;
        }
        img {
            max-width: 100%;
        }
        span {
            display: block;
            font-size: 24px;
            line-height: 1.1;
            a {
                text-decoration: none;
                color: $sl-green;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
