#ui-datepicker-div.ui-datepicker {
    z-index: 111002!important;
}

.ui-datepicker {
    box-shadow: 0 2px 7px 0 #c5c5c5;

    .ui-widget-header {
        border: 0;
        background: #fff;
        color: $gray-400;
    }

    .ui-datepicker-month {
        margin-right: 5px!important;
    }

    select.ui-datepicker-month,
    select.ui-datepicker-year {
        padding: 4px;
        &:focus {
            outline-color: $primary;
        }
    }

    .ui-datepicker-prev-hover, .ui-datepicker-next-hover {
        top: 2px!important;
    }

    .ui-datepicker-prev-hover {
        left: 2px!important;
    }

    .ui-datepicker-next-hover {
        right: 2px!important;
    }

    .ui-datepicker-prev-hover, .ui-datepicker-next-hover {
        border: 1px solid $primary!important;
        background: $info!important;
    }

    .ui-state-default,
    .ui-widget-content .ui-state-default,
    .ui-widget-header .ui-state-default,
    .ui-button,
    html .ui-button.ui-state-disabled:hover,
    html .ui-button.ui-state-disabled:active {
        text-align: center;
    }
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
    border: 1px solid $primary!important;
    background: $primary!important;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
    border: 1px solid $secondary!important;
    background: $secondary!important;
    color: #fff!important;
}

.ui-widget,
.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
    font-family: Montserrat,Helvetica Neue,Helvetica,Verdana,Arial,sans-serif!important;
}
