/* Downloads */
section.downloads {
    .card {
        margin-bottom: 20px;
        transition: all .3s;

        .icon {
            width: 140px;
            height: 155px;
            transition: transform 0.6s;
        }
        .card-body {
            .content {
                h4 {
                    font-size: 18px;
                    font-weight: 400 !important;
                }
            }
            button {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
        &:hover {
            transform: scale(1.035);
            transition: all .3s;

            .icon {
                transform: rotateY(180deg);
                transition: transform 0.6s;
            }
        }
    }
}
