/* Builder Blocks - Centered hero */

/* Centered Hero */
.centered-hero {
    padding-bottom: 35px !important;
}

/* Left Hero */
.left-hero {
    position: relative;
    overflow: hidden;

    .bg-image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
        opacity: .55;
    }
    &.grey-bg {
        .bg-image {
            display: none;
        }
    }
}

/* Narrow Hero */
.narrow-hero {
    p {
        margin-bottom: 0;
    }
    .cta-btns {
        padding: 0 0 0 24px;
        margin: auto;

        a {
            text-decoration: none !important;
        }

        @media (max-width: 767px) {
            padding: 20px 0 4px 0;
        }
    }
}
