/* Builder Blocks - Media Showcase */
.media-showcase {
    .gallery-area {
        .owl-theme {
            .owl-stage-outer {
                overflow: hidden;
                @extend .rounded;
            }
            .owl-nav {
                position: absolute;
                top: auto;
                left: 50%;
                bottom: -8px;
                font-size: 24px;
                height: 0px;
                width: 100%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);

                button {
                    opacity: 1;
                }
                .fas {
                    color: $sl-green;

                    &:hover {
                        color: $sl-green-dark;
                    }
                }
                .owl-prev {
                    left: 3px;
                }
                .owl-next {
                    right: 3px;
                }
            }
        }
        .owl-thumbs {
            .owl-thumb-item:hover {
                cursor: pointer;
            }
        }
    }
    .content {
        h4 {
            margin-bottom: 0;
        }
        blockquote {
            padding: 1.5rem 0.3rem !important;
            margin-bottom: 0;

            p {
                font-size: 20px;
                line-height: 26px;
                margin-bottom: 0;
            }
        }
    }
}
