/* Builder Blocks - Featured blocks */

/* Featured Image */
.featured-image {
    .image-area {
        overflow: hidden;
        float: left;

        .image {
            width: 100%;
        }
    }
    .content {
        blockquote {
            margin: 0;
            padding-bottom: 10px;
        }
    }
}

@media (max-width: 767px) {
    .featured-image {
        .image-area {
            margin-bottom: 20px;

            .image {
                width: 100% !important;
                max-height: inherit !important;
            }
        }
    }
}

/* Featured Video */
.featured-video {
    &.brand-1-bg {
        border: 2px solid $sl-green;
    }
    &.brand-2-bg {
        border: 2px solid $gray-700;
    }
    &.grey-bg {
        border: 2px solid $gray-200;
    }
    &.white-bg {
        border: 2px solid $white;
    }
    .video-area {
        max-height: 302px;
        float: left;

        @media (max-width: 767px) {
            margin-bottom: 20px;
        }

        @media (max-width: 575px) {
            max-height: inherit !important;
        }

        .youtube-video-overlay {
            overflow: hidden;

            .video {
                width: auto;
                max-height: 270px;

                @media (max-width: 767px) {
                    width: 100% !important;
                    max-height: inherit !important;
                }
            }
            .darken {
                position: absolute;
                width: calc(100% - 48px);
                height: 100%;
                background: rgba(0, 0, 0, .35);
                transition: all .25s;
            }
            .play {
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);


                .icon {
                    font-size: 64px;
                    color: $white;
                    opacity: .8;
                    transition: all .25s;
                }
            }

            &:hover .play .icon {
                opacity: 1;
                transition: all .25s;
                transform: scale(1.3);
            }
            &:hover .darken {
                transition: all .25s;
                background: rgba(0, 0, 0, .15);
            }
        }
    }
    .content {
        .btn {
            margin-bottom: -1rem;
        }
    }
}
